<template>
   <td v-on:click="">
      
   </td>
</template>
<script>
//Tools
import { formatData, loading } from '../../store/tools.js';
//Components
import PaperDisplayer from "../papers/PaperDisplayer.vue";
export default {
   name: "ui-cell",
   components: {
      PaperDisplayer,
   },
   props: {
      data:{
         type: Object,
         default: null
      },
      display: {
         type: Object,
         default: null
      },
      id: {
         type: String,
         default: null
      },
   },
   data() {
      return {
         lData: null,
         cell: null,
      }
   },
   created() {
      this.lData = {...this.data}
      let data = this.lData[this.display.attributeName]
      switch (this.display.inputType) {
         case 'text':
            this.cell = data
         break;
         case 'date':
            setTimeout(() => {
               document.getElementById(this.id).innerHTML = formatData('date', this.lData[this.display.attributeName])[0] +'<br>'+formatData('date', this.lData[this.display.attributeName])[1]
            }, 100);
         break;
      }
   },
};
</script>
<style lang="">
</style>