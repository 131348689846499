<template>
  <h1>{{pageName}}</h1>
  <p style="color: #949494; margin:0; font-size: 12px; "><strong>Page Form: </strong>{{ pageForm }}</p> 
  <p style="color: #949494; margin:0; font-size: 12px;"><strong>Page Table: </strong>{{ pageTable }}</p> 
  <p style="color: #949494; margin:0; font-size: 12px;"><strong>Entity: </strong>{{ entity }}</p> 
  <p style="color: #949494; margin:0; font-size: 12px;"><strong>Page Query: </strong>{{ pageQuery }}</p>
  <p style="color: #949494; margin:0; font-size: 12px;"><strong>Page Module: </strong>{{ pageModule }}</p>

  <div class="smart-wrap">
    <div class="input-wrap col-md-3">
      <label for="">Search for names..</label>
      <input  type="text" id="myInput" v-on:keyup="searchTable" title="Type in a name" v-model="modelSearch" />
    </div>
    <div class="input-wrap col-md-2">
      <label for="">View </label>
      <select v-model="recordStatus" v-on:change="getRecords(pageQuery)">
        <option value="1">Active</option>
        <option value="2">Archived</option>
        <option value="0">Deleted</option>
        <option value="3">All but Deleted</option>
        <option value="4">All</option>
      </select> 
    </div>
    <div class="input-wrap col-md-2">
      <label for="">Rows </label>
      <input type="number" min="0" v-model="l_tableRows" v-on:click="getRecords(pageQuery)" >
    </div>
    <div class="element-wrap col-md-5">
      <button class="btn-g btn-green" data-toggle="modal" data-target="#modal-update" v-on:click="setRecord()" >
        <svg class="btn-svg">
          <rect x="0" y="0" fill="none" width="100%" height="100%" />
        </svg>
        New {{entity.value.charAt(0).toUpperCase() + entity.value.slice(1).toLowerCase()}}
      </button>
      <button class="btn-g btn-gray" v-on:click="f_ListDataSet()" >
        <svg class="btn-svg">
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
        <i class='bx bx-refresh'></i>
      </button>
    </div>
  </div>
  
  <h1>--------------------------------------------</h1>
</template>
<script>

//AWS - GRAPHQL
import { smd_listPAGWithData, smd_getSMLItem, smd_composeEmailTemplate, smd_getRootDataSet  } from '../../graphql/queries'
import { smd_updateSmartRecord, smd_setItemState, smd_sendEmail2 } from '../../graphql/mutations'
import { onUpdateItem, onActivateRecord  } from '../../graphql/subscriptions'
//Tools
import Vuex from 'vuex'
import { API, Storage } from 'aws-amplify'
import $ from "jquery";
import Swal from 'sweetalert2'
import trumbowyg  from 'trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css';
//import { v4 as uuidv4 } from 'uuid';
//Components
import PaperLayout from './PaperLayout.vue'
import PaperDisplayer from './PaperDisplayer.vue'

export default {
  name: "paper-table",
  components:{
    PaperLayout,
    PaperDisplayer,
  },
  data() {
    return {
      //Component Functionalities
      modelSearch:'', //Input value search
      recordStatus:'1', //Record Status
      l_tableRows: this.tableRows, //Table Rows
    };
  },
  created() {
    console.clear()
  },
  methods: {
    //Vuex
    ...Vuex.mapMutations(["SetTableRows"]),
    //Tools
    goodFormat(data, format){
      let vdata = ''
      try {        
        switch (format) {
          case 'string':
            vdata = JSON.stringify(data, null,4)
          break;
          case 'json':
            vdata =JSON.parse(JSON.stringify(data))
          break;
        }
        return vdata
      } catch (error) {
        console.log('-->Good Format Error: '+format+'\n', data);
      }
    },
    //Component Functionalities
    searchTable() {
      console.clear()
      console.log('--------------------------------');
      let table = document.getElementById("myTable");
      let tr = table.children[1].children;
      console.log(this.v_search);
      console.log('Hola');
      for (let index = 0; index < tr.length; index++) {
        let w_td = tr[index].children
        table.children[1].children[index].classList.add("searchTable");
        for (let w_i_td = 0; w_i_td < w_td.length - 1; w_i_td++) {
          if (table.children[1].children[index].children[w_i_td].innerText.toLowerCase().includes(this.v_search.toLowerCase())) {
            //console.log(table.children[1].children[index].children[w_i_td].innerText);
            table.children[1].children[index].classList.remove("searchTable");
            //Resaltado
            //table.children[1].children[index].children[w_i_td].innerHTML = tr[index].children[w_i_td].innerText.replace(this.v_search.toLowerCase(), '<span style="color:#29a72e;font-weight:bolder">' + this.v_search.toLowerCase() + "</span>");
          }
        }
      }
    },

  },
  computed: {
    ...Vuex.mapState(["organizationID", "pageForm", "pageTable", "pageName", "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "tableRows"]),
  },
  watch:{
    pageQuery:function(value) {
      this.w_dataColumns= [{ label: "Loading...", attributeName: "no" }]
      this.w_dataTable= [ { no: 'Loading...' } ]
      console.log(value);
      this.f_ListDataSet(value)
    },
  }
}
</script>