<template>

  <br>
  <div class="row">
    <span class="col-md-6">
      <h2 >
        <span v-for="(name, index) in setNameContact(dataHistory)" :key="index">
          <span v-if=" name != '' " >
            {{name +'&nbsp;'}} 
          </span>
        </span>
      </h2>
      <p>Contact Dashboard</p>
      <br>
      <p> {{$router.currentRoute.value.query.data}} </p>
    </span>
    <span class="col-md-6">
      <ui-button style="margin: 0 0 0 10px;" :color="'gray'" :position="'right'" :text="''" v-on:click="getContactHistory($router.currentRoute.value.query.data)" >
        <i class='bx bx-refresh'></i>
        </ui-button>
    </span>
  </div>
  <br>
  <div class="smart-wrap " style="padding: 20px 0;"  v-for="(data, index) in dataHistory" :key="index">
    <div class="smart-wrap col-md-12"  style="padding: 10px; background:#ccc; align-items: center;" >
      <h5 class="col-md-6" data-toggle="collapse" :href="'#collapse-'+data.SK.replace('#','-')" role="button" aria-expanded="false"><strong><i class='bx bxs-right-arrow' style="font-size:12px; "></i> {{data.entityName.value}}</strong></h5>
      <div class="smart-wrap col-md-6" style="text-align: right; display: block;"  >
        <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Edit'" v-on:click="getItem(data, data.entity.value, 'edit')" data-toggle="modal" data-target="#modal-update" />
        <ui-button style="margin: 0 0 0 10px;" :color="'blue'" :position="'right'" :text="'Add'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <span v-if="entitiesView[data.entityName.value]">            
            <a  class="dropdown-item"  data-toggle="modal" data-target="#modal-update" v-for="(decendents, vindex) in entitiesView[data.entityName.value].inheritors" :key="vindex" v-on:click="getItem(data,  decendents['metaEntity.descendantEntities'] , 'new' )">
              {{ decendents['metaEntity.descendantEntities'].value }}
            </a>
          </span>
          <span v-else>
            Inheritors not Found
          </span>
        </div>
        <ui-button style="margin: 0 0 0 10px;" :color="'red'" :position="'right'" :text="'Delete'" data-toggle="modal" data-target="#modal-active"/>
      </div>
    </div>
    <div class=" col-md-12  collapse show" :id="'collapse-'+data.SK.replace('#','-')" style="padding:10px;" >
      <div class="smart-wrap">
        <div style="border-bottom:1px solid #ccc;"  :class="setCol(att)"  v-for="(att, key) in data" :key="key"  >
          <span style="font-weight: 500;" v-if="att.label != undefined">{{att.label+': '}}</span> <br> 
          <div v-if="att.inputType == 'Range' && att.label != undefined" class="status">
            <div class="bg">
              <svg width="300" height="8" viewBox="0 0 300 8" fill="none">
                <line y1="4" x2="100%" y2="4" stroke="#005aff" stroke-opacity="0.5" stroke-width="8"/>
              </svg>   
            </div>
            <div class="line">
              <svg width="300" height="8" viewBox="0 0 300 8" fill="none">
                <line y1="4" :x2="att.value+'%'" y2="4" stroke="#005aff" stroke-opacity="1" stroke-width="8"/>
              </svg>                  
            </div>
          </div>
          <!--paper-displayer :value="att"/-->
          <div  v-if="att.inputType != 'Range' && att.label != undefined">
            <span style="font-weight: 300;" v-if="att.attributeType=='L'" > 
              <span v-for="(value, key) in att.value" :key="key"> 
                <span v-if=" typeof value =='object' ">
                  <span v-for="(bvalue, vindex) in value" :key="vindex">
                    {{bvalue}}
                  </span>
                </span>
                <span v-else>
                  {{ value  }} 
                </span>
              </span>
            </span> 
            <span style="font-weight: 300;" v-if="att.attributeType=='S'" > 
              {{att.value}}
            </span> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Modal Active-->
  <div id="modal-active"  class="modal fade modal-default" role="dialog">
    <div class="modal-dialog delete" style="">
      <!-- Modal content-->
      <div class="modal-content">
        <!-- Modal buttons--> 
        <div class="wrap-head">
          <div class="row">
            <div class="col wrap-title">
              <h4 class="title-g">
                Want to Delete
                {{ viewItem.PK + " - " + viewItem.SK }}
              </h4>
            </div>
            <div class="col wrap-button">
              <button class="btn-red btn-g show" data-dismiss="modal">
                <svg class="btn-svg">
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                X
              </button>
              <button class="btn-green btn-g show" data-dismiss="modal"
                v-on:click="f_active(viewItem.PK, viewItem.SK)">
                <svg class="btn-svg">
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Modal Edit-->
  <div id="modal-update" class="modal fade modal-default" role="dialog" v-if="viewItem.entityName">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <!-- Modal buttons-->
        <div class="smart-wrap">
          <div class="col-md-5 wrap-title">
            <h2 class="title-g">{{viewItem.entityName.value}} </h2>
            <p class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">Developer Tools</p>
            <div class="collapse" id="infoItem">
              <div class="card card-body">
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;"> <strong>Creted By: </strong> {{viewItem.createdBy}}</p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;"> <strong>PK: </strong> {{viewItem.PK}}</p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;"> <strong>SK: </strong> {{viewItem.SK}}</p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;"> <strong>Parent </strong> {{viewItem.parent}}</p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;"> <strong>EntityItem </strong> <paper-displayer :value="viewItem.entity" /></p>
              </div>
            </div>
          </div>
          <div class="col-md-7 wrap-button">
            <ui-button style="margin: 0 0 0 10px;" :color="'gray'" :position="'right'" :text="'X'" data-dismiss="modal"/>
            <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Save'" :id="'save-'+viewItem.SK" v-on:click="updateItem(viewItem, viewEntity)" data-dismiss="modal"/>
            <!--ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Save and close'" data-dismiss="modal" v-on:click="updateItem(viewItem, viewEntity)"/-->
          </div>
        </div>
        <!-- Modal body-->
        <div class="smart-wrap ">
          <div class="input-wrap col-md-4">
            <label for="">System Type</label>
            <select class="form-control" v-model="viewItem.PK">
              <option selected disabled hidden>select an option</option>
              <option value="#SMARTDASH">System</option>
              <option :value="organizationID">
                {{ organizationID.replace("ORG#", "") }}
              </option>
            </select>
          </div>
          <paper-layout :layout="layout" :models="models" :data="{PK:viewItem.PK, SK:viewItem.SK}" />
          <h5 class="col-md-12">Config</h5> 
          <div class="input-wrap col-md-3" >
            <label for="">Groups Can Edit</label>
            <div  v-for="(check, zindex) in permissions" :key="zindex">
              <input type="checkbox" class="box" :value="check.value" v-model="groupsCanEdit"/>
              <span class="text" > {{check.value}} </span>
            </div>
          </div>
          <div class="input-wrap col-md-3" >
            <label for="">Groups Can Read</label>
            <div  v-for="(check, zindex) in permissions" :key="zindex">
              <input type="checkbox" class="box" :value="check.value" v-model="groupsCanRead"/>
              <span class="text" > {{check.value}} </span>
            </div>
          </div>
          <div class="input-wrap col-md-3" >
            <label for="">User Can Edit</label>
            <div >
              <input type="checkbox" class="box" value="@author" v-model="usersCanEdit"/>
              <span class="text" > @Author </span>
            </div>
            <div >
              <input type="checkbox" class="box" value="@client" v-model="usersCanEdit"/>
              <span class="text" > @Client </span>
            </div>
            <div  >
              <input type="checkbox" class="box" value="Responsible" v-model="usersCanEdit"/>
              <span class="text" > Responsible </span>
            </div>
          </div>
          <div class="input-wrap col-md-3" >
            <label for="">User Can Read</label>
            <div >
              <input type="checkbox" class="box" value="@author" v-model="usersCanRead"/>
              <span class="text" > @Author </span>
            </div>
            <div >
              <input type="checkbox" class="box" value="@client" v-model="usersCanRead"/>
              <span class="text" > @Client </span>
            </div>
            <div>
              <input type="checkbox" class="box" value="Responsible" v-model="usersCanRead"/>
              <span class="text" > Responsible </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//AWS - GRAPHQL
import { smd_getRootDataSet, smd_getSMLItem } from '../../graphql/queries'
import { smd_updateSmartRecord, } from '../../graphql/mutations'
//Tools
import { API } from "aws-amplify";
import Vuex from 'vuex'
//Components
import draggable from "vuedraggable";
import PaperDisplayer from './PaperDisplayer.vue';
import PaperLayout from './PaperLayout.vue';
import UiButton from '../ui/UiButton.vue'

export default {
  name:'paper-constructor',
  components:{
    draggable,
    PaperDisplayer,
    PaperLayout,
    UiButton,
  },
  props:{

  },
  data() {
    return {
      dataHistory: '',
      viewItem:[],
      viewEntity:'',
      //Action Item
      layout : [],
      models:[],
      mode:'new',
      //Permissons
      usersCanEdit:[],
      usersCanRead:[],
      groupsCanEdit:["Admin","Superadmin","Editor"],
      groupsCanRead:["Admin","Superadmin","Editor"],  
      vdata: [],
      entitiesView:[],
    }
  },
  created() {
    this.getContactHistory(this.$router.currentRoute.value.query.data);
  },
  methods: {
    //View Item
    async getContactHistory( id ){
      console.log('getContactHistory');
      try {
        const wo_PullItem = await API.graphql({
          query: smd_getRootDataSet,
          variables: {
            GSP1PK1: id,
          },
        });
        console.log(wo_PullItem.data.smd_getRootDataSet);
        console.log(JSON.parse(wo_PullItem.data.smd_getRootDataSet.data));
        console.log(JSON.parse(wo_PullItem.data.smd_getRootDataSet.entities));
        this.dataHistory = JSON.parse(wo_PullItem.data.smd_getRootDataSet.data)
        this.entitiesView = JSON.parse(wo_PullItem.data.smd_getRootDataSet.entities)
        for (const key in this.entitiesView) {
          console.log( this.entitiesView[key].inheritors);
          try {
            this.entitiesView[key].inheritors = JSON.parse(this.entitiesView[key].inheritors)
            if (this.entitiesView[key].inheritors.length == 0 || typeof this.entitiesView[key].inheritors == 'string') {
              this.entitiesView[key].inheritors = null
            }
          } catch (error) {
            this.entitiesView[key].inheritors = null
            console.log('--> Fallo: '+key);
          }
        }
        for (let vindex = 0; vindex < this.dataHistory.length; vindex++) {
          const item = this.dataHistory[vindex];
          for (let index = 0; index < this.entities.length; index++) {
            const element = this.entities[index];
            if ( item.entity.SK == element.SK) {
              element.attributes.forEach(att => {
                if (att.attributeName == "SML.descendantEntitiesWithForm") {
                  this.dataHistory[vindex].decendents = JSON.parse(att.attributeValue)
                }
              });
            }
          }
        }
        this.vdata = []
        //console.log(JSON.parse(wo_PullItem.data.smd_getRootDataSet.data));
        for (let zindex = 0; zindex < JSON.parse(wo_PullItem.data.smd_getRootDataSet.data).length; zindex++) {
          const element = JSON.parse(wo_PullItem.data.smd_getRootDataSet.data)[zindex];
          //console.log(element);
          for (const key in element) {
            //console.log(element[key]);
          }
          console.log('--------------------------');
        }
      } catch (error) {
        console.log(error);
      }
    },
    //View Points
    async getItem( item, entity, mode ) {
      // Set Mode
      console.log(item);
      console.log(entity);
      this.mode = mode
      if ( this.mode == 'new') {
        console.log("%cNew Mode\nPK:" + entity.PK, "color:#0084ff");
      }else{
        console.log("%cEdit Mode\nPK:" + item.PK + ",  SK:" + item.SK, "color:#6BF01A");
      }
      this.viewItem = item
      this.viewEntity = entity
      console.log(this.viewEntity);
      //Set Parent ----------
      let parent = null
      if (item.parent) {
        parent = item.parent.value
      }
      //Set SML
      let sml = null
      for (let index = 0; index < this.entities.length; index++) {
        const element = this.entities[index];
        if ( entity.SK == element.SK) {
          element.attributes.forEach(att => {
            if (att.attributeName =='metaEntity.defaultFormLayout') {
              sml = JSON.parse(att.attributeValue)
            }
          });
        }
      }
      if (sml.length == 0) {
        sml = item.form.value
      }
      let dataPull = await API.graphql({
        query: smd_getSMLItem,
        variables: {
          itemPK: item.PK,
          itemSK: item.SK,
          SMLPK: sml.PK,
          SMLSK: sml.SK,
          parent: parent,
          entity:{
            PK: entity.PK,
            SK: entity.SK,
          }
        },
      });
      //Set Data ----------
        console.log("--> Data of Query :",  dataPull);
      let binds = []
      this.models = []
      dataPull =  dataPull.data.smd_getSMLItem
      this.layout = JSON.parse(dataPull.data)
      this.models = JSON.parse(dataPull.item).attributes
      binds = JSON.parse(dataPull.item).attributes
      this.usersCanEdit = JSON.parse(dataPull.item).usersCanEdit
      this.usersCanRead = JSON.parse(dataPull.item).usersCanRead
      this.groupsCanEdit = JSON.parse(dataPull.item).groupsCanEdit
      this.groupsCanRead = JSON.parse(dataPull.item).groupsCanRead
      //Model Verification
      for (const key in binds) {
        try {
          if (binds[key].type == 'L') {
            binds[key].value = JSON.parse(this.models[key].value)
          }
          //console.log(key+': '+JSON.stringify(this.models[key],null,4));
        } catch (error) {
          console.error('Error en: '+ key);
          //console.error(error);
        }
        
      }
      console.log(binds);
      this.models = binds
      console.log("----> Layout:",  this.layout);
      console.log("----> Item Details:",  JSON.parse(dataPull.item));
      console.log("----> Models :",  this.models);
      console.log("----> Sml :",  sml);
    },
    //Save
    async updateItem( item , entity ){
      console.log(entity);
      let push =''
      //Set format Models
      for (const key in this.models) {
        try {
          if (this.models[key].type == 'L') {
            this.models[key].value = JSON.stringify(this.models[key].value)
          }
          if (this.models[key].type == 'HTML') {
            this.models[key].value = escape(this.models[key].value)
          }
        } catch (error) {
          console.error(error);
        }
      }
      //
      let parent = null
      if (item.parent) {  
        parent = item.parent
      }else{
        if (this.mode == 'new') {
          parent = {PK:item.PK, SK: this.$router.currentRoute.value.query.data ,}
        }
      }
      //Set Mode
      let SK = null
      if (this.mode == 'edit') {
        SK = item.SK
      }
      //Set Details Entity
      let sml = ''
      let shortEntity = ''
      for (let index = 0; index < this.entities.length; index++) {
        const element = this.entities[index];
        if ( entity.SK == element.SK) {
          console.log(element.attributes);
          element.attributes.forEach(att => {
            if (att.attributeName =='metaEntity.defaultFormLayout') {
              sml = JSON.parse(att.attributeValue)
            }
            if (att.attributeName =='metaEntity.metaShortEntity') {
              shortEntity = att.attributeValue
            }
          });
        }
      }
      if (sml.length == 0) {
        sml = item.form.value
      }
      console.log(parent);
      try {
        push = await API.graphql({
          query: smd_updateSmartRecord, //smd_updateSmartRecord
          variables: { 
            input:{
              shortEntity: shortEntity,
              PK: this.viewItem.PK,
              SK: SK,
              attributesStr: JSON.stringify(this.models) ,
              entity: {PK: entity.PK,SK: entity.SK},
              form: {             
                PK: sml.PK,
                SK: sml.SK,
              },
              parent: parent.value,
              usersCanEdit:this.usersCanEdit,
              usersCanRead: this.usersCanRead ,
              groupsCanEdit: this.groupsCanEdit,
              groupsCanRead: this.groupsCanRead,
            }
          },
        });
        console.log('Update');
        console.log(push);
        this.getContactHistory(this.$router.currentRoute.value.query.data);
      } catch (error) {
        console.log('Fail');
        console.log(error);
        console.log(push);
      }
    },
    //Others
    setCol(att){
      let col = 'col-md-2'
      if (att.col != undefined) {
        col = 'col-md-'+att.col
      }
      if (att.label == undefined || att.visible == false) {
        col = 'displayNone'
      }
      return col
    },
    setNameContact(dataHistory){
      let name = ''
      try {
        name = dataHistory[0]['sml.contactFullName'].value
      } catch (error) {
        console.log(error);
      }
      return name
    },
    log: function (evt) {
      console.log(evt);
    },
  },
  computed:{ 
    ...Vuex.mapState(["organizationID", "pageForm", "pageTable", "pageName", "pageQuery", "entity", "pageMeta", "pageModule", "permissions", "entities"]),
    dragOptions() {
      return {
        animation: 200,
        group: "inputs",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  }
}
</script>