<template>
  <div class="smart-wrap absoluteCenterFlex" style="justify-content: space-between;padding: 10px 0 0 0;">
    <div class="absoluteCenterFlex" > 
      <div class="options">
        <ui-accion-button v-if="entities[pageQuery] && entities[pageQuery].icon" v-on:click="renderComponent = !renderComponent">
          <img v-if="JSON.parse(entities[pageQuery].icon)[0]" :src="JSON.parse(entities[pageQuery].icon)[0].url" alt="">
        </ui-accion-button>
      </div>
      <h4>{{dataModule['modules.pageName']}}</h4>
      <div class="options">
        <ui-accion-button action="refresh" style="zindex:999; opacity:0;" v-if="entities[pageQuery] " id="refreshTable" v-on:click="refreshTable(pageQuery)"/>
        <router-link v-if="pageQuery == 'MPR'" :to="{paht:'/post', name:'Post', query:{mode: 'design'}}" target=”_blank”>
          <ui-accion-button action="check"/>
        </router-link>
        <ui-accion-button action="refresh" v-on:click="setSearchContact('');refreshTable(pageQuery)"/>
        <ui-accion-button action="filter" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"/>
        <ui-accion-button action="new" v-on:click="actionRecord(dataModule, 'new')"/>
      </div>
      <div class="dropdown">
        <div class="options " href="#" role="button" data-toggle="dropdown" aria-expanded="false">
          <ui-accion-button style="float: right; background: gray;" v-on:click="table.action = !table.action">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M5 23.7q-.825 0-1.413-.588Q3 22.525 3 21.7v-14q0-.825.587-1.413Q4.175 5.7 5 5.7h8.925L7 12.625V19.7h7.075L21 12.75v8.95q0 .825-.587 1.412q-.588.588-1.413.588Zm4-6v-4.25l7.175-7.175l4.275 4.2l-7.2 7.225Zm12.875-8.65L17.6 4.85l1.075-1.075q.6-.6 1.438-.6q.837 0 1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8Z"/></svg>
          </ui-accion-button>
        </div>
        <div class="dropdown-menu">
          <a class="dropdown-item" v-show="action.permits && JSON.parse(action.permits).some(item =>userPermissions.includes(item))" v-for="(action, index) in  dataModule.actions" v-on:click="selectItemsTable( action.routine, action.callType.toLowerCase()) " :key="index" href="#">{{ action.label  }} </a>
        </div>
      </div>
    </div>
  </div>
  <div class="collapse" id="collapseExample" style="width: 100%;">
    <!-- Filters -->
    <div class="smart-wrap">  
      <div class="input-wrap col-md-12"  v-if="false">
        <label style="width:100%;" for="">View</label>
        <select name="" id="" style="width:20%;" v-model="table.display"  v-on:change="displayTable()">
          <option value="table">List</option>
          <option value="calendar">Calendar</option>
          <option value="toDo">To Do</option>
          <option value="gant">Gannt</option>
        </select>
      </div>  
      <div class="input-wrap col-md-2">
        <label style="width:100%;" for="">Search..</label>
        <input   type="text" id="searchTableInput" v-on:keyup.enter="searchTable()" title="Type in a name" v-model="search" autocomplete="off"/>
        <i class="bx bx-x clearSearch"  v-on:click="search = ''; searchTable()"></i>
      </div>
      <div class="input-wrap col-md-1">
        <label for="">View </label>
        <select v-model="table.status" v-on:change="getDataTable()">
          <option value="1">Active</option>
          <option value="2">Archived</option>
          <option value="0">Deleted</option>
          <option value="3">All but Deleted</option>
          <option value="4">All</option>
        </select> 
      </div>
      <div class="input-wrap col-md-1"> 
        <label for="">Rows </label>
        <!--input type="number" min="0" v-model="pageSize" v-on:change="initTable()"-->
        <input type="number" min="10" step="10" max="250" v-on:blur="setRows()" v-model="table.pageSize" >
      </div>
      <div v-if="dataModule.allowSelectPK == true && this.userPermissions.includes('Superadmin')" class="input-wrap col-md-1">
        <div>
          <label for="">PK</label>
          <select name="" id=""  v-model="table.PK" v-on:change="getDataTable()">
            <option selected disabled hidden>select an option</option>
            <option value="#SMARTDASH">System</option>
            <option :value="item.value" v-for="(item, index) in preloadLists['#ORG']" :key="index">
              {{ item.value.replace("ORG#", "")}}
            </option>
          </select>
        </div>    
        
      </div>
      <div v-if="dataModule.preload != true" class="input-wrap col-md-1">
        <label for="">Status</label>
        <select name="" id="" v-if="pageQuery != '#SML'" v-model="table.filterStatus" v-on:change="setSearchContact(''); table.index='';table.date='';getDataTable();">
          <option :value="option" v-for="(option, index) in filter.status" :key="index">{{option}}</option> 
          <option value="All" >All</option> 
        </select>
        <select  v-model="table.filterStatus" v-if="pageQuery == '#SML'">
          <option selected disabled hidden>select an option</option>
          <option value="form">Form</option>
          <option value="menu">Menu</option>
          <option value="post">Post</option>
          <option value="webpage">Webpage</option>
          <option value="quiz">Quiz</option>
          <option value="smartForm">Smart Form</option>
          <option value="All">All</option>
      </select>
      </div>
      <div v-if="dataModule.preload != true" class="input-wrap col-md-1">
        <label for="">Index</label>
        <select name="" id=""  v-model="table.index"  v-on:change="setSearchContact(''); table.filterStatus='';">
          <option value="Created">Created</option>
          <option v-if="filter.GSI4Label" :value="filter.GSI4Label">{{filter.GSI4Label}}</option>
        </select>
      </div>
      <div v-if="dataModule.preload != true" class="input-wrap col-md-1">
        <label for="">Range</label>
        <select name="" id=""  v-model="table.date"  v-on:change="setSearchContact(''); table.filterStatus='';">
          <option value="Today">Today</option>
          <option value="Yesterday">Yesterday</option>
          <option value="Last 7 Days">Last 7 Days</option>
          <option value="This Month">This Month</option>
          <option value="Last 30 Days">Last 30 Days</option>
          <option value="This Year">This Year</option>
          <option value="Range">Range</option>  
        </select>
      </div>
      <div v-if="table.date == 'Range'" class="input-wrap col-md-1">
        <label for=""> &nbsp;</label>
        <input type="date" name="" id="" v-model="table.customRange[0]">
      </div>
      <div v-if="table.date == 'Range'" class="input-wrap col-md-1">
        <label for="">&nbsp; </label>
        <input type="date" name="" id="" v-model="table.customRange[1]" >
      </div>
      <div  class="input-wrap col-md-1">
        <ui-button text='Apply' style="cursor: pointer;margin: 17px 0 0 0;display: block;" v-on:click="getDataTable();"/>
      </div>
      <div  class="input-wrap col-md-1">
        <ui-button text='' class="absoluteCenterFlex" color="blue" style="padding: 10px;cursor: pointer;margin: 17px 0 0 0;" v-on:click="saveFilters();">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="m19 9l-1.25-2.75L15 5l2.75-1.25L19 1l1.25 2.75L23 5l-2.75 1.25L19 9Zm0 14l-1.25-2.75L15 19l2.75-1.25L19 15l1.25 2.75L23 19l-2.75 1.25L19 23ZM9 20l-2.5-5.5L1 12l5.5-2.5L9 4l2.5 5.5L17 12l-5.5 2.5L9 20Z"/></svg>
        </ui-button>
      </div>
    </div>
  </div>
  <div class="table-wrap" v-if="table.display == 'table' && widthScreen > 767">
    <div class="pagination">
      <a href="#" v-show="table.pagination[2] != 1" v-on:click="table.pagination[2] = table.pagination[2]-1; table.pagination[1] = (table.pagination[2]-1)*table.pageSize;f_RenderComponent();">&laquo;</a>
      <a href="#" :class="{ active: table.pagination[2] == index+1}" :id="'pagination-'+index" v-for="(item, index) in table.pagination[0]" :key="index" v-on:click="  table.pagination[1] = (index)*table.pageSize; table.pagination[2] =index+1;f_RenderComponent();" > {{ index+1 }} </a>
      <a href="#" v-show="table.pagination[2] != table.pagination[0]" v-on:click="table.pagination[1] = (table.pagination[2])*table.pageSize; table.pagination[2] = table.pagination[2]+1; f_RenderComponent();">&raquo;</a>
    </div>
    <!--ul class="list-group">
      <li class="list-group-item" v-for="(item, rIndex) in table.ram" :key="rIndex">{{item['sml.contactFullName']}}</li>
    </ul-->
    <ui-table>
      <template #head>
        <th class="index">
          <span>
            <input style="width: 20px; height: 20px;" type="checkbox" name="" id="" v-on:change="(event)=>{ selectItemsTable(event, 'all') } ">
          </span>
        </th>
        <th v-for="(head, hIndex) in table.headers" :key="hIndex" v-on:click="()=>{ table.headers[hIndex].order = !table.headers[hIndex].order; sortTable(hIndex+1)}"> 
          {{head.label}}
        </th>
        <th v-if="!this.userPermissions.includes('Guest')" >options</th>
      </template>
      <template #body>
        <tr class="tr-hover" v-for="(item, rIndex) in table.ram.slice(table.pagination[1], table.pagination[1]+table.pageSize )" :key="rIndex">
          <td class="index">  
            <span v-if="item.subscribe">
              <i v-if="item.subscribe == 'edit'" class='bx bxs-pencil' style='color:#5ad911'></i>
              <i v-if="item.subscribe == 'new'" class='bx bxs-star' style='color:#0085ff'  ></i>
            </span>
            <span >
              {{rIndex+1}}
            </span>
            <span>
              <input :id="rIndex+item.SK" style="width: 20px; height: 20px;" type="checkbox" name="" id="" v-on:change="(event)=>{ selectItemsTable(event, 'select', item) } ">
            </span>
          </td>
          <td v-for="(head, hIndex) in table.headers" :style="head.style" :key="hIndex" v-on:click="actionRecord(item, 'update', head)" style="overflow: hidden;">
            <!--p v-if="head.value && renderComponent"  v-for="(shead, sIndex) in head.value" :key="sIndex" v-on:click="actionRecord(item)">
              <span v-if="item[shead.attributeName] != ''" style="display:block;">
                <strong v-if="shead.label && shead.label != ''">{{shead.label}}</strong> 
                <ui-recursive-display :vdata="item[shead.attributeName]" :format="shead.inputType" :id="'cell-'+shead.attributeName+'-'+rIndex" type="combo" :name="shead.attributeName"/>
              </span>
            </!--p>
            <ui-recursive-display v-if="!head.value && renderComponent" :vdata="item[head.attributeName]" :format="head.inputType" :id="'cell-'+head.attributeName+'-'+rIndex" :name="head.attributeName"/-->
            <span v-if="head.inputType &&  item[head.attributeName] &&  head.inputType.toLowerCase() == 'date'">
              {{formatData('date', item[head.attributeName])[0]}}
              <br>
              {{formatData('date', item[head.attributeName])[1]}}
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'range'">
              <div class="progress" :style="'background:'+organizationData.colorSeriesA[1]+'!important;'">
              <div class="progress-bar bg-success" role="progressbar" :style="'width:'+item[head.attributeName]+'%; background:'+organizationData.colorSeriesA[0]+'!important; color:'+organizationData.colorSeriesA[1]+'!important;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{item[head.attributeName]}}%</div>
              </div>
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'boolean'">
              <i v-if="item[head.attributeName] == true" class='bx bxs-check-circle' :style="'color:'+organizationData.colorSeriesA[0]+'; font-size:25px; opacity:0.9;'"></i>
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'currency'">
              {{formatData('currency', item[head.attributeName])}} 
            </span>
            <span :id="'cell-'+head.attributeName+'-'+rIndex" v-else-if="head.inputType && head.inputType.toLowerCase() == 'html'">
              {{formatData('html', [ item[head.attributeName], 'cell-'+head.attributeName+'-'+rIndex])}} 
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'phone'" class="absoluteCenterFlex">
              <span title="Open Virtual Phone">
                <svg  class="hoverOpacity" style="margin:0 10px 0 0;" v-on:click="openPhone()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="#333" d="M24 13h-2a3.003 3.003 0 0 0-3-3V8a5.006 5.006 0 0 1 5 5Z"/><path fill="#333" d="M28 13h-2a7.008 7.008 0 0 0-7-7V4a9.01 9.01 0 0 1 9 9zm-7.667 8.482l2.24-2.24a2.167 2.167 0 0 1 2.337-.48l2.728 1.092A2.167 2.167 0 0 1 29 21.866v4.961a2.167 2.167 0 0 1-2.284 2.169C7.594 27.806 3.732 11.61 3.015 5.408A2.162 2.162 0 0 1 5.169 3h4.873a2.167 2.167 0 0 1 2.012 1.362l1.091 2.728a2.167 2.167 0 0 1-.48 2.337l-2.24 2.24s1.242 8.732 9.908 9.815z"/></svg>
              </span>
              <span title="Copy Phone Number">
                {{formatData('phone', item[head.attributeName])}} 
              </span>
            </span>
            <span v-else-if="head.inputType == 'file'" class="absoluteCenterFlex">
              <span  v-for="(file, index) in item[head.attributeName]" :key="index">  
                <img :src="file.url" :alt="file.url" :style="head.style">
              </span>
            </span>
            <span v-else-if="head.attributeName == 'layout'" class="absoluteCenterFlex">
              {{item[head.attributeName].value}} 
            </span>
            <span v-else>
              {{item[head.attributeName]}}
            </span>
          </td>
          <td v-if="!this.userPermissions.includes('Guest')" class="options-td">
            <div class="options">   
              <div class="dropdown show" v-if="entities[dataModule['modules.dataQuery']]">
                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Action">
                  <ui-accion-button action="action"/>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" style="cursor: pointer; overflow:hidden;"  v-for="(action, aindex) in JSON.parse(entities[dataModule['modules.dataQuery']]['SML.descendantEntitiesWithForm'])" :key="aindex"  v-on:click=" setgRecord([item, 'action',aindex]);" data-toggle="modal" data-target="#modal-update">
                    
                    <ui-accion-button v-if="action['metaEntity.descendantEntities'].id && action['metaEntity.descendantEntities'].id.length == 3 && entities[action['metaEntity.descendantEntities'].id].icon">
                        <img :src="JSON.parse(entities[action['metaEntity.descendantEntities'].id].icon)[0].url" alt="">
                      </ui-accion-button>
                    <span v-if="action['metaEntity.descendantEntities'].SK =='#ENT#f9910928-2e9d-4e4b-9a43-19fc1ead11a1'" style="padding: 6px 0; display: block;">
                      &nbsp;{{action['modules.formSK'].value}}
                    </span>
                    <span v-else style="padding: 6px 0; display: block;">
                      &nbsp;{{action['metaEntity.descendantEntities'].value}}
                    </span>
                  </a>
                </div>
              </div>
              <!-- Action Records -->
              <router-link v-if="this.pageQuery == 'MPR'"  :to=" { path:'/smartmaker',query: { name:'SMLUP', PK:item.layout.PK, SK:item.layout.SK, post:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="pageQuery == 'BLO' || pageQuery == 'WEB' || pageQuery == 'PRO'"  :to=" { path:'/smarpage',query: { PK:item.PK, SK:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/smartmaker',query: {  name:'SMLUP', PK:item.PK, SK:item.SK, mode:'generate'}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">                            
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/webpreview',query: {  sml:item.SK,}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">                    
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"/></svg>
                </div>
              </router-link>
              <a style="cursor: pointer"  data-toggle="modal" data-target="#modal-send"  v-on:click="setgRecord([item, 'email'])">
                <ui-accion-button action="send"/>
              </a>
              <router-link  v-if="item.GSP1PK1" :to="{ paht:'/dashboard', query:{ name: 'clietDash', data: item.GSP1PK1 }}" v-on:click="forceUpdate()">
                <ui-accion-button action="dash"/>
              </router-link>
              <a style="cursor: pointer" v-if="pageQuery == 'PHC' && !item.GSP1PK1" v-on:click="createLead(item)" >
                <ui-accion-button action="user"/>
              </a>
              <a style="cursor: pointer" v-if="pageQuery == 'PHO' || (pageQuery== 'PHC' && item.phoneNumber) || pageQuery== 'CON'"  v-on:click="setgRecord([item, 'sms'])">
                <ui-accion-button action="sms"/>
              </a>
              <a v-if="pageQuery == 'QUO'" :href="organizationData.publicAliasURL+'/checkout?data='+item.SK.replace('#','%23')" target="_blank">
                <ui-accion-button action="checkout" style="height: 35px; padding: 8px;"/>
              </a>
              <a style="cursor: pointer" v-on:click="cloneRecod(item)" >
                <ui-accion-button action="clone"/>
              </a>
              <!-- State Record Management -->
              <a style="cursor: pointer" v-if="pageQuery == 'USR'" v-on:click="addUser(item)" >
                <ui-accion-button action="user"/>
              </a>
              <!-- State Record Management -->
              <a v-if="item.active == '0' || item.active == '1'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Archive'])" >
                <ui-accion-button action="archive"/>                                                                                                                                                                                                                                                           
              </a>
              <a v-if="item.active == '2' " style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
                <ui-accion-button action="desarchive"/>
              </a>
              <a v-if="(item.active == '0' || item.active == '2') && item.PK != '#SMARTDASH'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
                <ui-accion-button action="delete" />
              </a>
              <a v-if="item.active == '1' && item.PK != '#SMARTDASH'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Delete'])" >
                <ui-accion-button action="delete" />
              </a>
            </div>
          </td>
        </tr>
      </template>
    </ui-table>
    <div class="pagination">
      <a href="#" v-show="table.pagination[2] != 1" v-on:click="table.pagination[2] = table.pagination[2]-1; table.pagination[1] = (table.pagination[2]-1)*table.pageSize;">&laquo;</a>
      <a href="#" :class="{ active: table.pagination[2] == index+1}" :id="'pagination-'+index" v-for="(item, index) in table.pagination[0]" :key="index" v-on:click="table.pagination[1] = (index)*table.pageSize; table.pagination[2] =index+1" > {{ index+1 }} </a>
      <a href="#" v-show="table.pagination[2] != table.pagination[0]" v-on:click="table.pagination[1] = (table.pagination[2])*table.pageSize; table.pagination[2] = table.pagination[2]+1">&raquo;</a>
    </div>
  </div>
  <div class="table-wrap" v-if="table.display == 'table'&& widthScreen <= 767">
    <div class="pagination">
      <a href="#" v-show="table.pagination[2] != 1" v-on:click="table.pagination[2] = table.pagination[2]-1; table.pagination[1] = (table.pagination[2]-1)*table.pageSize;f_RenderComponent();">&laquo;</a>
      <a href="#" :class="{ active: table.pagination[2] == index+1}" :id="'pagination-'+index" v-for="(item, index) in table.pagination[0]" :key="index" v-on:click="  table.pagination[1] = (index)*table.pageSize; table.pagination[2] =index+1;f_RenderComponent();" > {{ index+1 }} </a>
      <a href="#" v-show="table.pagination[2] != table.pagination[0]" v-on:click="table.pagination[1] = (table.pagination[2])*table.pageSize; table.pagination[2] = table.pagination[2]+1; f_RenderComponent();">&raquo;</a>
    </div>
    <!--ul class="list-group">
      <li class="list-group-item" v-for="(item, rIndex) in table.ram" :key="rIndex">{{item['sml.contactFullName']}}</li>
    </ul-->
    <div class="list-group" v-if="false">
      <a href="#" :style="{ 'background:#000;':rIndex == 1}" class="list-group-item list-group-item-action" v-for="(item, rIndex) in table.ram.slice(table.pagination[1], table.pagination[1]+table.pageSize )" :key="rIndex" >
        <div style="display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #eaeaea;padding:5px 0;" v-for="(head, hIndex) in table.headers" :key="hIndex" v-on:click="actionRecord(item, 'update', head)">
          <strong>{{head.label}}: </strong>
          <span v-if="head.inputType &&  item[head.attributeName] &&  head.inputType.toLowerCase() == 'date'">
            {{formatData('date', item[head.attributeName])[0]}}
            <br>
            {{formatData('date', item[head.attributeName])[1]}}
          </span>
          <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'range'">
            <div class="progress" :style="'background:'+organizationData.colorSeriesA[1]+'!important;'">
            <div class="progress-bar bg-success" role="progressbar" :style="'width:'+item[head.attributeName]+'%; background:'+organizationData.colorSeriesA[0]+'!important; color:'+organizationData.colorSeriesA[1]+'!important;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{item[head.attributeName]}}%</div>
            </div>
          </span>
          <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'boolean'">
            <i v-if="item[head.attributeName] == true" class='bx bxs-check-circle' :style="'color:'+organizationData.colorSeriesA[0]+'; font-size:25px; opacity:0.9;'"></i>
          </span>
          <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'currency'">
            {{formatData('currency', item[head.attributeName])}} 
          </span>
          <span :id="'cell-'+head.attributeName+'-'+rIndex" v-else-if="head.inputType && head.inputType.toLowerCase() == 'html'">
            {{formatData('html', [ item[head.attributeName], 'cell-'+head.attributeName+'-'+rIndex])}} 
          </span>
          <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'phone'" class="absoluteCenterFlex">
            <span title="Open Virtual Phone">
              <svg  class="hoverOpacity" style="margin:0 10px 0 0;" v-on:click="openPhone()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="#333" d="M24 13h-2a3.003 3.003 0 0 0-3-3V8a5.006 5.006 0 0 1 5 5Z"/><path fill="#333" d="M28 13h-2a7.008 7.008 0 0 0-7-7V4a9.01 9.01 0 0 1 9 9zm-7.667 8.482l2.24-2.24a2.167 2.167 0 0 1 2.337-.48l2.728 1.092A2.167 2.167 0 0 1 29 21.866v4.961a2.167 2.167 0 0 1-2.284 2.169C7.594 27.806 3.732 11.61 3.015 5.408A2.162 2.162 0 0 1 5.169 3h4.873a2.167 2.167 0 0 1 2.012 1.362l1.091 2.728a2.167 2.167 0 0 1-.48 2.337l-2.24 2.24s1.242 8.732 9.908 9.815z"/></svg>
            </span>
            <span title="Copy Phone Number">
              {{formatData('phone', item[head.attributeName])}} 
            </span>
          </span>
          <span v-else-if="head.inputType == 'file'" class="absoluteCenterFlex">
            <span  v-for="(file, index) in item[head.attributeName]" :key="index">  
              <img :src="file.url" :alt="file.url" :style="head.style">
            </span>
          </span>
          <span v-else-if="head.attributeName == 'layout'" class="absoluteCenterFlex">
            {{item[head.attributeName].value}} 
          </span>
          <span style="text-align:right;" v-else>
            {{item[head.attributeName]}}
          </span>
        </div>
        <div v-if="!this.userPermissions.includes('Guest')" class="options" style="display: flex; align-items: center; flex-wrap: nowrap; justify-content: center; padding: 20px 0 0 0;">
          <div class="dropdown show" v-if="entities[dataModule['modules.dataQuery']]">
            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Action">
              <ui-accion-button action="action"/>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" style="cursor: pointer; overflow:hidden;"  v-for="(action, aindex) in JSON.parse(entities[dataModule['modules.dataQuery']]['SML.descendantEntitiesWithForm'])" :key="aindex"  v-on:click=" setgRecord([item, 'action',aindex]);" data-toggle="modal" data-target="#modal-update">
                
                <ui-accion-button v-if="action['metaEntity.descendantEntities'].id && action['metaEntity.descendantEntities'].id.length == 3 && entities[action['metaEntity.descendantEntities'].id].icon">
                    <img :src="JSON.parse(entities[action['metaEntity.descendantEntities'].id].icon)[0].url" alt="">
                  </ui-accion-button>
                <span v-if="action['metaEntity.descendantEntities'].SK =='#ENT#f9910928-2e9d-4e4b-9a43-19fc1ead11a1'" style="padding: 6px 0; display: block;">
                  &nbsp;{{action['modules.formSK'].value}}
                </span>
                <span v-else style="padding: 6px 0; display: block;">
                  &nbsp;{{action['metaEntity.descendantEntities'].value}}
                </span>
              </a>
            </div>
          </div>
          <!-- Action Records -->
          <router-link v-if="this.pageQuery == 'MPR'"  :to=" { path:'/smartmaker',query: { name:'SMLUP', PK:item.layout.PK, SK:item.layout.SK, post:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
            <div class="col send">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
            </div>
          </router-link>
          <router-link v-if="pageQuery == 'BLO' || pageQuery == 'PRO'"  :to=" { path:'/smarpage',query: { PK:item.PK, SK:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
            <div class="col send">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
            </div>
          </router-link>
          <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/smartmaker',query: {  name:'SMLUP', PK:item.PK, SK:item.SK, mode:'generate'}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
            <div class="col send">                            
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
            </div>
          </router-link>
          <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/webpreview',query: {  sml:item.SK,}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
            <div class="col send">                    
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"/></svg>
            </div>
          </router-link>
          <a style="cursor: pointer"  data-toggle="modal" data-target="#modal-send"  v-on:click="setgRecord([item, 'email'])">
            <ui-accion-button action="send"/>
          </a>
          <router-link  v-if="item.GSP1PK1" :to="{ paht:'/dashboard', query:{ name: 'clietDash', data: item.GSP1PK1 }}" v-on:click="forceUpdate()">
            <ui-accion-button action="dash"/>
          </router-link>
          <a style="cursor: pointer" v-if="pageQuery == 'PHC' && !item.GSP1PK1" v-on:click="createLead(item)" >
            <ui-accion-button action="user"/>
          </a>
          <a style="cursor: pointer" v-if="pageQuery == 'PHO' || (pageQuery== 'PHC' && item.phoneNumber) || pageQuery== 'CON'"  v-on:click="setgRecord([item, 'sms'])">
            <ui-accion-button action="sms"/>
          </a>
          <a v-if="pageQuery == 'QUO'" :href="organizationData.publicAliasURL+'/checkout?data='+item.SK.replace('#','%23')" target="_blank">
            <ui-accion-button action="checkout" style="height: 35px; padding: 8px;"/>
          </a>
          <a style="cursor: pointer" v-on:click="cloneRecod(item)" >
            <ui-accion-button action="clone"/>
          </a>
          <!-- State Record Management -->
          <a style="cursor: pointer" v-if="pageQuery == 'USR'" v-on:click="addUser(item)" >
            <ui-accion-button action="user"/>
          </a>
          <!-- State Record Management -->
          <a v-if="item.active == '0' || item.active == '1'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Archive'])" >
            <ui-accion-button action="archive"/>                                                                                                                                                                                                                                                           
          </a>
          <a v-if="item.active == '2' " style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
            <ui-accion-button action="desarchive"/>
          </a>
          <a v-if="item.active == '0' || item.active == '2'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
            <ui-accion-button action="delete" />
          </a>
          <a v-if="item.active == '1'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Delete'])" >
            <ui-accion-button action="delete" />
          </a>
        </div>
      </a>
    </div>
    <ui-table>
      <template #head>
        <th class="index">
          <span>
            <input style="width: 20px; height: 20px;" type="checkbox" name="" id="" v-on:change="(event)=>{ selectItemsTable(event, 'all') } ">
          </span>
        </th>
        <th v-for="(head, hIndex) in table.headers" :key="hIndex" v-on:click="()=>{ table.headers[hIndex].order = !table.headers[hIndex].order; sortTable(hIndex+1)}"> 
          {{head.label}}
        </th>
        <th v-if="!this.userPermissions.includes('Guest')" >options</th>
      </template>
      <template #body>
        <tr class="tr-hover" v-for="(item, rIndex) in table.ram.slice(table.pagination[1], table.pagination[1]+table.pageSize )" :key="rIndex">
          <td class="index">  
            <span v-if="item.subscribe">
              <i v-if="item.subscribe == 'edit'" class='bx bxs-pencil' style='color:#5ad911'></i>
              <i v-if="item.subscribe == 'new'" class='bx bxs-star' style='color:#0085ff'  ></i>
            </span>
            <span >
              {{rIndex+1}}
            </span>
            <span>
              <input :id="rIndex+item.SK" style="width: 20px; height: 20px;" type="checkbox" name="" id="" v-on:change="(event)=>{ selectItemsTable(event, 'select', item) } ">
            </span>
          </td>
          <td v-for="(head, hIndex) in table.headers" :style="head.style" :key="hIndex" v-on:click="actionRecord(item, 'update', head)" style="overflow: hidden;">
            <!--p v-if="head.value && renderComponent"  v-for="(shead, sIndex) in head.value" :key="sIndex" v-on:click="actionRecord(item)">
              <span v-if="item[shead.attributeName] != ''" style="display:block;">
                <strong v-if="shead.label && shead.label != ''">{{shead.label}}</strong> 
                <ui-recursive-display :vdata="item[shead.attributeName]" :format="shead.inputType" :id="'cell-'+shead.attributeName+'-'+rIndex" type="combo" :name="shead.attributeName"/>
              </span>
            </!--p>
            <ui-recursive-display v-if="!head.value && renderComponent" :vdata="item[head.attributeName]" :format="head.inputType" :id="'cell-'+head.attributeName+'-'+rIndex" :name="head.attributeName"/-->

            <span v-if="head.inputType &&  item[head.attributeName] &&  head.inputType.toLowerCase() == 'date'">
              {{formatData('date', item[head.attributeName])[0]}}
              <br>
              {{formatData('date', item[head.attributeName])[1]}}
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'range'">
              <div class="progress" :style="'background:'+organizationData.colorSeriesA[1]+'!important;'">
              <div class="progress-bar bg-success" role="progressbar" :style="'width:'+item[head.attributeName]+'%; background:'+organizationData.colorSeriesA[0]+'!important; color:'+organizationData.colorSeriesA[1]+'!important;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{item[head.attributeName]}}%</div>
              </div>
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'boolean'">
              <i v-if="item[head.attributeName] == true" class='bx bxs-check-circle' :style="'color:'+organizationData.colorSeriesA[0]+'; font-size:25px; opacity:0.9;'"></i>
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'currency'">
              {{formatData('currency', item[head.attributeName])}} 
            </span>
            <span :id="'cell-'+head.attributeName+'-'+rIndex" v-else-if="head.inputType && head.inputType.toLowerCase() == 'html'">
              {{formatData('html', [ item[head.attributeName], 'cell-'+head.attributeName+'-'+rIndex])}} 
            </span>
            <span v-else-if="head.inputType && head.inputType.toLowerCase() == 'phone'" class="absoluteCenterFlex">
              <span title="Open Virtual Phone">
                <svg  class="hoverOpacity" style="margin:0 10px 0 0;" v-on:click="openPhone()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="#333" d="M24 13h-2a3.003 3.003 0 0 0-3-3V8a5.006 5.006 0 0 1 5 5Z"/><path fill="#333" d="M28 13h-2a7.008 7.008 0 0 0-7-7V4a9.01 9.01 0 0 1 9 9zm-7.667 8.482l2.24-2.24a2.167 2.167 0 0 1 2.337-.48l2.728 1.092A2.167 2.167 0 0 1 29 21.866v4.961a2.167 2.167 0 0 1-2.284 2.169C7.594 27.806 3.732 11.61 3.015 5.408A2.162 2.162 0 0 1 5.169 3h4.873a2.167 2.167 0 0 1 2.012 1.362l1.091 2.728a2.167 2.167 0 0 1-.48 2.337l-2.24 2.24s1.242 8.732 9.908 9.815z"/></svg>
              </span>
              <span title="Copy Phone Number">
                {{formatData('phone', item[head.attributeName])}} 
              </span>
            </span>
            <span v-else-if="head.inputType == 'file'" class="absoluteCenterFlex">
              <span  v-for="(file, index) in item[head.attributeName]" :key="index">  
                <img :src="file.url" :alt="file.url" :style="head.style">
              </span>
            </span>
            <span v-else-if="head.attributeName == 'layout'" class="absoluteCenterFlex">
              {{item[head.attributeName].value}} 
            </span>
            <span v-else>
              {{item[head.attributeName]}}
            </span>
          </td>
          <td v-if="!this.userPermissions.includes('Guest')" class="options-td">
            <div class="options">   
              <div class="dropdown show" v-if="entities[dataModule['modules.dataQuery']]">
                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Action">
                  <ui-accion-button action="action"/>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" style="cursor: pointer; overflow:hidden;"  v-for="(action, aindex) in JSON.parse(entities[dataModule['modules.dataQuery']]['SML.descendantEntitiesWithForm'])" :key="aindex"  v-on:click=" setgRecord([item, 'action',aindex]);" data-toggle="modal" data-target="#modal-update">
                    
                    <ui-accion-button v-if="action['metaEntity.descendantEntities'].id && action['metaEntity.descendantEntities'].id.length == 3 && entities[action['metaEntity.descendantEntities'].id].icon">
                        <img :src="JSON.parse(entities[action['metaEntity.descendantEntities'].id].icon)[0].url" alt="">
                      </ui-accion-button>
                    <span v-if="action['metaEntity.descendantEntities'].SK =='#ENT#f9910928-2e9d-4e4b-9a43-19fc1ead11a1'" style="padding: 6px 0; display: block;">
                      &nbsp;{{action['modules.formSK'].value}}
                    </span>
                    <span v-else style="padding: 6px 0; display: block;">
                      &nbsp;{{action['metaEntity.descendantEntities'].value}}
                    </span>
                  </a>
                </div>
              </div>
              <!-- Action Records -->
              <router-link v-if="this.pageQuery == 'MPR'"  :to=" { path:'/smartmaker',query: { name:'SMLUP', PK:item.layout.PK, SK:item.layout.SK, post:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="pageQuery == 'BLO' || pageQuery == 'WEB' || pageQuery == 'PRO'"  :to=" { path:'/smarpage',query: { PK:item.PK, SK:item.SK} }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/smartmaker',query: {  name:'SMLUP', PK:item.PK, SK:item.SK, mode:'generate'}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">                            
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
                </div>
              </router-link>
              <router-link v-if="this.pageQuery == '#SML'"  :to=" { path:'/webpreview',query: {  sml:item.SK,}  }" style="cursor: pointer"  data-placement="top" target="_blank" > 
                <div class="col send">                    
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"/></svg>
                </div>
              </router-link>
              <a style="cursor: pointer"  data-toggle="modal" data-target="#modal-send"  v-on:click="setgRecord([item, 'email'])">
                <ui-accion-button action="send"/>
              </a>
              <router-link  v-if="item.GSP1PK1" :to="{ paht:'/dashboard', query:{ name: 'clietDash', data: item.GSP1PK1 }}" v-on:click="forceUpdate()">
                <ui-accion-button action="dash"/>
              </router-link>
              <a style="cursor: pointer" v-if="pageQuery == 'PHC' && !item.GSP1PK1" v-on:click="createLead(item)" >
                <ui-accion-button action="user"/>
              </a>
              <a style="cursor: pointer" v-if="pageQuery == 'PHO' || (pageQuery== 'PHC' && item.phoneNumber) || pageQuery== 'CON'"  v-on:click="setgRecord([item, 'sms'])">
                <ui-accion-button action="sms"/>
              </a>
              <a v-if="pageQuery == 'QUO'" :href="organizationData.publicAliasURL+'/checkout?data='+item.SK.replace('#','%23')" target="_blank">
                <ui-accion-button action="checkout" style="height: 35px; padding: 8px;"/>
              </a>
              <a style="cursor: pointer" v-on:click="cloneRecod(item)" >
                <ui-accion-button action="clone"/>
              </a>
              <!-- State Record Management -->
              <a style="cursor: pointer" v-if="pageQuery == 'USR'" v-on:click="addUser(item)" >
                <ui-accion-button action="user"/>
              </a>
              <!-- State Record Management -->
              <a v-if="item.active == '0' || item.active == '1'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Archive'])" >
                <ui-accion-button action="archive"/>                                                                                                                                                                                                                                                           
              </a>
              <a v-if="item.active == '2' " style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
                <ui-accion-button action="desarchive"/>
              </a>
              <a v-if="item.active == '0' || item.active == '2'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Active'])" >
                <ui-accion-button action="delete" />
              </a>
              <a v-if="item.active == '1'" style="cursor: pointer" data-toggle="modal" data-target="#modal-status" v-on:click="setgRecord([item, 'status', 'Delete'])" >
                <ui-accion-button action="delete" />
              </a>
            </div>
          </td>
        </tr>
      </template>
    </ui-table>
    <div class="pagination">
      <a href="#" v-show="table.pagination[2] != 1" v-on:click="table.pagination[2] = table.pagination[2]-1; table.pagination[1] = (table.pagination[2]-1)*table.pageSize;">&laquo;</a>
      <a href="#" :class="{ active: table.pagination[2] == index+1}" :id="'pagination-'+index" v-for="(item, index) in table.pagination[0]" :key="index" v-on:click="table.pagination[1] = (index)*table.pageSize; table.pagination[2] =index+1" > {{ index+1 }} </a>
      <a href="#" v-show="table.pagination[2] != table.pagination[0]" v-on:click="table.pagination[1] = (table.pagination[2])*table.pageSize; table.pagination[2] = table.pagination[2]+1">&raquo;</a>
    </div>
  </div>
  <div class="smart-wrap calendar-wrap" v-if="table.display == 'calendar'">
    <div class="col-calendar">
      <div class="wrap-header">
        <div class="pre" v-on:click="actionCalendar('back', 'table', new Date() ); ">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></svg>
        </div>
        <div class="info">
            <div class="head-month" :id="'month-'+'table'"></div>
            <div class="head-day" :id="'day-'+'table'"></div>
        </div>
        <div class="next" v-on:click="actionCalendar('next', 'table', new Date() );">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g transform="translate(24 0) scale(-1 1)"><path fill="#5a5a5a" fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm2.207 7.707a1 1 0 0 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L10.914 12l3.293-3.293Z" clip-rule="evenodd"/></g></svg>
        </div>
      </div>
      <table :id="'calendar-'+'table'" class="preview-calendar" >
        <thead>
          <tr>
            <th>S</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(week, windex) in 6" :key="windex" > 
            <td v-for="(day, dindex) in 7" :key="dindex" :id="'days-table'" @click="(event) => {table.calendarDay = event.target.title; actionCalendar('init','table', new Date(event.target.title)) }">
                <a>{{day}}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-content">
      
      <div class="table-wrap" >
        <ui-table>
          <template #head>
            <th class="index"></th>
            <th>data</th>
          </template>
          <template #body>
            <tr class="tr-hover" v-for="(hours, rIndex) in table.calendar" :key="rIndex">
              <td class="index">
                {{rIndex}}
              </td>
              <td class="td-calendar">
                <div v-for="(hour, tindex) in hours" :key="tindex" class="meet" :style="'width:calc( '+100 /hours.length+'% - 10px );'"  v-on:click="actionRecord(hour)">
                  <strong style="font-size:16px;">{{hour.subject}}</strong>  | {{hour.responsible.value}}
                </div> 
              </td>
            </tr>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
  <!--Modal Sms-->
  <ui-modal name="dddd" type="sms" classModal="modal-dialog-centered" >
    <template #content v-if="dataSMS != undefined">
      <div class="wrap-head">
        <div class="row">
          <div class="col wrap-title" v-if="dataSMS.phone != undefined">
            <h4 class="title-g"> Send SMS </h4>
            {{formatData( 'phone' ,dataSMS.phone.replace('PHO#', ''))}}
          </div>
          <div class="col wrap-button">
            <ui-button style="margin: 0 0 0 10px;" :color="'red'" :text="'Cancel'" :position="'right'" data-dismiss="modal"/>
            <ui-button style="margin: 0 0 0 10px;" :color="'green'" :text="'Send'" :position="'right'" data-dismiss="modal" v-on:click="msmActions('send', dataSMS)"/>
          </div>
        </div>
      </div>  
      <div class="smart-wrap">
        <div class="input-wrap col-12">
          <label v-if=" dataSMS.options.length != 0" for="">Options</label>
            <div class="envelop" v-show="option['phone.verified'] == true"  v-for="(option, zindex) in dataSMS.options" :key="zindex">
              <input type="radio" class="check input" :value="'PHO#'+option.phoneNumber" v-model="dataSMS.phone" /> 
              <span class="check text" > {{ formatData('phone',option.phoneNumber)}} </span>
            </div>
        </div>
        <div class="input-wrap col-12">
          <label for="">Message</label>
          <textarea name="" id="" cols="30" rows="10" v-model="dataSMS.message"></textarea>
        </div>
      </div>
    </template>
  </ui-modal>
</template>
<script>

//AWS - GRAPHQL
import { smd_listPAGWithData2,smd_listEntityObj, smd_composeEmailTemplate, smd_backendCustomQuery } from '../../graphql/queries'
import { smd_sendSMS, smd_sendEmail2, smd_cloneSmartRecord, smd_createInternalUser, smd_createLead2, smd_sendEmailCampaign, smd_backendCustomMutation, smd_updateAttributes } from '../../graphql/mutations'
import { onUpdateSmartRecord, onCloneItem, onSetItemState, onUpdateAttributes } from '../../graphql/subscriptions'

//Tools
import Vuex from 'vuex'
import { API, Storage } from 'aws-amplify'
import Swal from 'sweetalert2'
import { formatData, loading, popUp, calendar, actionCalendar, processRecordsToDisplay,recordToDisplay, orderArray, checkDateFilter } from '../../store/tools.js';

//Components
import PaperLayout from './PaperLayout.vue'
import PaperDisplayer from './PaperDisplayer.vue'

import UiButton from '../ui/UiButton.vue'
import UiRecursiveDisplay from '../ui/UiRecursiveDisplay.vue'
import UiAccionButton from '../ui/UiAccionButton.vue'
import UiModal from '../ui/UiModal.vue'
import UiTable from '../ui/UiTable.vue'
import UiCell from '../ui/UiCell.vue'


export default {
  name: "paper-table",
  components:{
    PaperLayout,
    PaperDisplayer,
    UiButton,
    UiRecursiveDisplay,
    UiAccionButton,
    UiModal,
    UiTable,
    UiCell,
  },
  data() {
    return {
      search:'',
      renderComponent:true,
      widthScreen:'',
      table:{
        //DATA
        headers:[
          { label:'a', attributeName:'a', inputType: 'text'},
          { label:'b', attributeName:'b', inputType: 'number'}
        ],
        body: [
          { a:'1', b:2 },
          { a:'1', b:2 },
        ],
        // Read Access Memory of data Table
        ram: [ ],
        // Records selected of table 
        select: [ ],
        //calendar - (options)
        calendar: { 
          '7:00':[], '7:30':[], '8:00':[], '8:30':[], '9:00':[], '9:30':[], '10:00':[], '10:30':[], '11:00':[], '11:30':[], '12:00':[],  '12:30':[], '13:00':[], '13:30':[], '14:00':[], '14:30':[], '15:00':[], '15:30':[], '16:00':[], '16:30':[], '17:00':[], '17:30':[], '18:00':[], '18:30':[], '19:00':[],
        },
        calendarDay:undefined,
        //Filters
        PK: '#SMARTDASH',
        status:'1',
        filterStatus: 'All',
        index: '', //rangeFilter
        date: '', 
        customRange:[new Date().toISOString().split('T')[0],new Date().toISOString().split('T')[0]],
        //Display
        action:true,
        display:'table',
        pagination: [10, 0, 1],
        pageSize: 50,
      },
      filter:{
        status:[],
        GSI4Label:undefined
      },
      //SMS
      dataSMS:{
        options:[]
      },
      //Email - Review Laates, maybe not uses ( before made modal component )
      emailTemplates:[],
      subjectEmail:[],
      toEmail:[],
    }
  },
  created() {
    console.clear()
    this.widthScreen = window.screen.width
    this.table.PK = this.organizationID
    if (this.organizationID == 'ORG#SYSTEM') {
      this.table.PK == '#SYSTEM'
    }
    //delete
    
    this.table.filterStatus=''
    this.table.index=''
    this.table.date=''
    this.initTable(this.pageQuery)
    this.subscribe()
    
    //------------
    setTimeout(() => {
      calendar()
    }, 500);
  },
  methods: {
    //Vuex
    ...Vuex.mapMutations(['setgRecord','setNotifications','setSearchContact','setSearchContact','setResponseActionTable']),
    ...Vuex.mapActions(['getRecordLists']),
    //Tools
    formatData(format, data){
      return formatData(format, data)
    },
    setRows(){
      if (this.table.pageSize == 0) {
        this.table.pagination[0] = Math.floor(this.table.body.length / 100) +1
        this.table.pageSize = 100
      }else{
        this.table.pagination[0] = Math.floor(this.table.body.length / this.table.pageSize) +1;
      }
    },
    f_RenderComponent(){
      this.renderComponent = false
      setTimeout(() => { this.renderComponent = true }, 10);
    },
    checkPermissons(data){
      let record = data
      record.edit = false
      record.read = false
      for (let index = 0; index < this.userPermissions.length; index++) {
        const element = this.userPermissions[index];
        try {
          if ( record.groupsCanEdit.includes(element)) {
            record.edit = true
          }
          if ( record.groupsCanRead.includes(element)) {
            record.read = true
          }
        } catch (error) {
          console.log(error)
        }
      }
      if ( record.usersCanRead.includes('@author')) {
        if ( record.createdBy == this.user) {
          record.edit = true
          record.read = true
        }
      }
      if (this.userPermissions.includes('Superadmin') || this.userPermissions.includes('Admin') || record.groupsCanEdit.includes('Public') || ( record.responsible && record.responsible.id == this.user)) {
        record.edit = true
        record.read = true
      }
      if (record.read == true  ) {
        
        //If record not have status, not push in table (except all)
        record = this.checkFilters(record)
      }
      if (this.search != '') {  
        record.filter= false
        for (const key in record) {
          try {
            if (typeof record[key] == 'string' &&record[key].includes(this.search)) {
              record.filter = true
            }
          } catch (error) {
            
          }
        }
      } 
      if (record.edit == true) {
        //record.read = true
        //record.filter = true
      }
      return record
    },
    checkFilters(record){
      if ((record[this.entities[this.pageQuery]['metaEntity.sortField3']] && record[this.entities[this.pageQuery]['metaEntity.sortField3']] == this.table.filterStatus) || this.table.filterStatus == 'All'  ) {
          record.filter = true
        }
        else if (this.table.index != '') {
          let date = new Date()
          if (this.table.index == 'Created') {
            date = record.createdAt
          }else{
            for (const key in record) {
              if (key.replace(/\s/g, '').toLowerCase() == this.table.index.replace(/\s/g, '').toLowerCase()) {
                date = record[key]
              }
            }
          }
          record.filter = checkDateFilter( date , this.table.date )
        }
        else{
          record.filter = false
        }
        return  record
    },
    async setEditor(SK){
      //console.clear()
      try {
        setTimeout(() => {
          $(`#editor${SK}`).trumbowyg({
            svgPath: false,
            hideButtonTexts: true
          });
          document.getElementById(`editor${SK}`).innerHTML = document.getElementById(`editor${SK}`).innerHTML + ''
        }, 1000);
      } catch (error) {
        
      }
    },
    openPhone(){
      if (document.getElementById('callBox').classList[1]) {
        document.getElementById('callBox').classList.remove('show')
      }else{
        document.getElementById('callBox').classList.add('show')
      }
    },
    async testSendPost(data){
      let pushData = ''
      try {
        pushData = await API.graphql({
          query: smd_sendEmailCampaign,
          variables: { 
            orgPK: this.organizationID,
            //type: $type,
            itemPK: data.PK,
            itemSK: data.SK,
          },
        }); 
        pushData = pushData.data.smd_sendEmailCampaign
        console.log(pushData);
        if (pushData.smd_response.statusCode != 200) {
          popUp(pushData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pushData.smd_response.popAlert == true) {
          popUp(pushData.smd_response, 'response', 'scc')
        }
        Swal.fire({
          icon: 'success',
          title: 'Successfully Send Post' ,
          //text: pushData.smd_response.statusCode
        })
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
    },
    //Data
    initTable(key){
      let type = 'local' 
      this.table.headers = []
      console.log(this.recordLists);
      if (this.recordLists[key]) {
        type = 'global'
      }
      this.table.body = []
      this.table.ram = []
      this.table.select = []
      /*switch (type) {
        case 'global':
          this.renderComponent = false
          console.log(key);
          if (key == 'CON') {
            this.table.headers = this.recordLists[key].headersV2
          }else{
            this.table.headers = this.recordLists[key].headers
          }
          this.table.body = this.recordLists[key].list
          this.table.ram = this.table.body

          setTimeout(() => { this.renderComponent = true }, 100);
          loading('hidden','refresh table Preload')
        break;
        case 'local':
          this.getDataTable()
        break;
        case 'client':
          
        break;
        
      }*/
      let filterPreference = {}// for preference of 1-User 2-Module
      try {
        filterPreference = this.dataUser.preferences ? this.dataUser.preferences.value.find(obj => obj.module === this.pageQuery): undefined
        if (filterPreference) {
          filterPreference = JSON.parse(filterPreference.defaultQuery);
        }
      } catch (error) {
        console.log(error)
        //popUp(error, 'cach', 'err')
      }
      this.getDataTable(filterPreference)
      this.setRows()
    },
    refreshTable(key){
      let type = 'local' 
      if (this.recordLists[key]) {
        type = 'global'
      }
      switch (type) {
        case 'global':
          loading('show','refreshTable')
          this.getRecordLists(this.dataModule)
          setTimeout(() => {
            this.initTable(key)
          }, 1000);
        break;
        case 'local':
          this.getDataTable()
        break;
        case 'client':
          
        break;
      }
      this.setRows()
    },
    async getDataTable(data) { 
      //loading('show')
      console.time()
      //console.log(this.table);
      let searchContact = undefined
      let pageSize = '0'
      if (this.searchContact != '' && this.pageQuery == "CON") {
        searchContact = this.searchContact
        pageSize = undefined
      }
      if (this.table.PK == "SMARTDASH") {
        this.table.PK = 'ORG#SMARTDASH'
      }
      let config ={
        active: this.table.status,
        pagPK: this.dataModule.PK,
        pagSK: this.dataModule.SK,
        dataPK: this.table.PK,
        pageSize:pageSize,
        lastEvaluatedKey: undefined,
        status: this.table.filterStatus,
        index: this.table.index, //rangeFilter
        range: this.table.date,
        customRange: JSON.stringify(this.table.customRange),
        searchKey: searchContact,
        ...data
      }
      console.log('Data to List Table: ',config);
      try {
        const wo_PullItem = await API.graphql({
          query: smd_listPAGWithData2,
          variables: config
        });
        let pullData =wo_PullItem.data.smd_listPAGWithData2
        //Response
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pullData.smd_response.popAlert == true) {
          popUp(pullData.smd_response, 'response', 'scc')
        }
        console.log('> Data Query: ', pullData );
        
        //this.table.headers = JSON.parse(pullData.header)
        //this.table.body = JSON.parse(pullData.data)
        if (pullData.smd_response.statusCode == '200') {   
          console.log(JSON.parse(pullData.filter));
          this.table.filterStatus= JSON.parse(pullData.filter).status
          this.table.index= JSON.parse(pullData.filter).index
          this.table.date= JSON.parse(pullData.filter).range
          this.table.body = processRecordsToDisplay(JSON.parse(pullData.data))
          this.table.ram =  processRecordsToDisplay(JSON.parse(pullData.data))
          this.table.headers = JSON.parse(pullData.header)
          //incer att order for sort Table Filter
          for (let i = 0; i < this.table.headers.length; i++) {
            this.table.headers[i].order = false;
          }
          this.filter.status = JSON.parse(pullData.status)
          this.filter.GSI4Label = pullData.GSI4Label
          //console.log(this.table.ram);
          if (this.pageQuery == "CAL") {
            console.log(this.pageQuery );
            this.table.calendar = { 
              '7:00':[], '7:30':[], '8:00':[], '8:30':[], '9:00':[], '9:30':[], '10:00':[], '10:30':[], '11:00':[], '11:30':[], '12:00':[],  '12:30':[], '13:00':[], '13:30':[], '14:00':[], '14:30':[], '15:00':[], '15:30':[], '16:00':[], '16:30':[], '17:00':[], '17:30':[], '18:00':[], '18:30':[], '19:00':[]
            }
            for (let index = 0; index < this.table.ram.length; index++) {
              const element = this.table.ram[index];
              try {
                this.table.calendar[element.time].push(element)
              } catch (error) {
                console.log(element);
              }
            }
            console.log(this.table.calendar);
          }

          this.setRows()
        }else{
          console.log('HERE ALERT');
          //this.statusCode(pullData.smd_response)
        }
        console.log('> Data Table: ', this.table );
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      if (this.table.pageSize == 0 ) {
        this.table.pageSize = 100
      }
      this.renderComponent = false
      setTimeout(() => {
        this.renderComponent = true
      }, 100);
      loading('hide')
      //console.timeEnd()
    },
    //Action with records
    forceUpdate(  ){
      let forceUpdate = document.getElementById('forceUpdate')
      console.log(forceUpdate);
      var event = new Event('click');
      setTimeout(() => {
        forceUpdate.dispatchEvent(event);
        console.log(event);
      }, 100);
    },
    actionRecord(item, action, cell){
      switch (action) {
        case 'new':
          console.log(this.pageQuery);
          if (this.pageQuery == '#SML') {
            //this.$router.push({  query: { name:'SMLUP', PK:undefined, SK:undefined, }, });
            let url = this.$router.resolve( { path:'/smartmaker',query: { name:'SMLUP', PK:this.table.PK } });
            console.log(url)
            window.open(url.href, '_blank')
            return
          }
          else{
            this.setgRecord([ item, 'new'])
            $('#modal-update').modal('show')
          }
        break;
        case 'update':
          try {
            if(cell.inputType && cell.inputType.toLowerCase() == 'phone'){
              if (!document.getElementById('callBox').classList[1]) {
                //document.getElementById('callBox').classList.add('show')
              }
              navigator.clipboard.writeText(item[cell.attributeName]).then(() => {
                //console.log('success')
              }, (err) => {
                //console.log(err)
              });
            }else{
              if (item.shortEntity == 'CON') {
                this.$router.push({ paht:'/dashboard', query:{ name: 'clietDash', data: item.GSP1PK1 }}); 
                this.forceUpdate();
                return
              }
              else if (item.shortEntity == '#SML') {
                let url = ''
                if (this.userPermissions.includes("Guest")) {
                  url = this.$router.resolve( { path:'/webpreview',query: {  sml:item.SK,}  } );
                } else {
                  //this.$router.push({ path:'/smartmaker',query: { name:'SMLUP', PK:item.PK, SK:item.SK, } });
                  url = this.$router.resolve( { path:'/smartmaker',query: { name:'SMLUP', PK:item.PK, SK:item.SK, } });
                }
                console.log(url)
                window.open(url.href, '_blank')
              }
              else{
                if (Object.keys(item.form).length === 0) {
                  item.form = this.dataModule['modules.formSK']
                }
                this.setgRecord([item, 'update'])
                //$('#modal-update').modal('show')
              }
            }
          } catch (err) {
            console.log(err);
          }
        break;
        default:
          this.setgRecord([item, 'update'])
          if (item.shortEntity == 'CON') {
            this.$router.push({ paht:'/dashboard', query:{ name: 'clietDash', data: item.GSP1PK1 }}); 
            this.forceUpdate();
          }
          else if (item.shortEntity == '#SML') {
            //this.$router.push({ path:'/smartmaker',query: { name:'SMLUP', PK:item.PK, SK:item.SK, } });
            let url = this.$router.resolve( { path:'/smartmaker',query: { name:'SMLUP', PK:item.PK, SK:item.SK, } });

            window.open(url.href, '_blank')
          }
          else{
            $('#modal-update').modal('show')
          }
        break;
      }
      console.log(item);
    },
    async cloneRecod(data) {
      loading('show','cloneRecod')
      let pushData = ''
      try {
        pushData = await API.graphql({
          query: smd_cloneSmartRecord,
          variables: { 
            PK: data.PK,
            SK: data.SK
          },
        }); 
        pushData = pushData.data.smd_cloneSmartRecord
        Swal.fire({
          icon: 'success',
          title: 'Successfully cloned' ,
          //text: pushData.smd_response.statusCode
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: error
        })
      }
      loading('hidde','cloneRecod')
    },
    async msmActions(action, data){
      switch (action) {
        case 'init':
          console.log(data);
          this.dataSMS.options = []
          this.dataSMS.message = ''
          this.dataSMS.PK = data.PK
          switch (data.shortEntity) {
            case "CON":
              this.dataSMS.options = data.SMLPhones
            break;
            case "PHO":
              this.dataSMS.phone = data.SK
            break;
            case "PHC":
              this.dataSMS.phone = 'PHO#'+data.phoneNumber
            break;
          }
        break;
        case 'send':
          let push = ''
          let config = {}
          try {     
            
            config={
              phonePK: data.PK,
              phoneSK: data.phone,
              message: data.message
            }
            console.log(config);
            push = await API.graphql({
              query: smd_sendSMS, //smd_updateSmartRecord
              variables: config
            });
            console.log('eviado exitosament');
            console.log(push);
            //smd_sendEmail2(bodyHTML: "hello hello", to: "marcelo@bizplaneasy.comm", subject: "hola from lambda")
            this.refreshTable(this.pageQuery)
            Swal.fire({
              icon: 'success',
              title: 'SMS sent successfully' ,
              //text: JSON.stringify(error),
            })
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'error' ,
              text: JSON.stringify(error),
            })
          }
        break;
      }
    },
    async emailAction(action, PK, SK, item){
      console.log(action);
      switch (action) {
        case 'list':
          try {
            this.toEmail = ""
            this.subjectEmail=""
            const wo_PullItem = await API.graphql({
              query: smd_listEntityObj,
              variables: {
                active: '1' ,
                PK: this.organizationID,
                shortEntity: 'EMT',
                pageSize: 0 ,
              },
            });
            console.log(JSON.parse(wo_PullItem.data.smd_listEntityObj.data));
            this.emailTemplates = JSON.parse(wo_PullItem.data.smd_listEntityObj.data)
          } catch (error) {
            console.log(error);
          }
        break;
        case 'set':
          try {              
            console.clear()
            console.log(this.organizationID +'-'+ SK);
            let pullData = await API.graphql({
              query: smd_composeEmailTemplate,
              variables: {
                emailTPK: this.organizationID,
                emailTSK: SK,
                itemPK: this.organizationID,
                itemSK: this.record.item.SK,
                itemGSP1PK1: this.record.item.GSP1PK1,
                itemGSP1SK1: this.record.item.GSP1SK1,
                baseURL: window.location.origin, 
              },
            });
            pullData = pullData.data.smd_composeEmailTemplate
            console.log(pullData);
            this.toEmail = ''
            this.toEmail = JSON.parse(pullData.data).emails
            this.subjectEmail = JSON.parse(pullData.data).subject
            this.emailTemplate = JSON.parse(pullData.data).body
            this.inputHtml('emailTemplate')
          } catch (error) {
            console.log(error);
          }
        break;
        case 'send':
          try {              
            let push = ''
            push = await API.graphql({
              query: smd_sendEmail2, //smd_updateSmartRecord
              variables: {
                to: this.toEmail,
                subject: this.subjectEmail,
                bodyHTML: this.emailTemplate
              },
            });
            console.log('eviado exitosament');
            console.log(push);
            //smd_sendEmail2(bodyHTML: "hello hello", to: "marcelo@bizplaneasy.comm", subject: "hola from lambda")
            Swal.fire({
              icon: 'success',
              title: 'Mail sent successfully' ,
              //text: JSON.stringify(error),
            })
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'error' ,
              text: JSON.stringify(error),
            })
          }
        break;
        case 'editor':
          try {       
            this.itemPK = PK
            this.itemSK = SK
            setTimeout(() => {      
              this.setEditor(SK)
            }, 1000);
          } catch (error) {
            console.log(error);
          }
        break;
        case 'editorQuote':
          try {       
            this.emailAction('list')
            
            try {
              
            } catch (error) {
              
            }
          } catch (error) {
            console.log(error);
          }             
        break;
      }
    },
    async addUser(data){
      loading('show','addUser')
      let pushData = ''
      let config = ''
      console.log(data);
      try {
        config ={ 
          userPK: data.PK,
          userSK: data.SK
        },
        pushData = await API.graphql({
          query: smd_createInternalUser,
          variables: config
        }); 
        pushData = pushData.data.smd_createInternalUser
        console.log(pushData);
        Swal.fire({
          icon: 'success',
          title: pushData.smd_response.userMessage ,
          text: pushData.smd_response.message
        })
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: error
        })
      }
      loading('hidde','addUser')
    },
    async createLead(data){
      loading('show','createLead')
      let pushData = ''
      let config = ''
      console.log(data);
      try {
        config ={ 
          itemPK: data.PK,
          itemSK: data.SK
        },
        pushData = await API.graphql({
          query: smd_createLead2,
          variables: config
        }); 
        pushData = pushData.data.smd_createLead2
        console.log(pushData);
        if (pushData.smd_response.statusCode != 200) {
          popUp(pushData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pushData.smd_response.popAlert == true) {
          popUp(pushData.smd_response, 'response', 'scc')
        }

      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      loading('hidde','createLead')
    },
    // Subscriptions ------------
    async subscribe() {
      API.graphql({  query: onUpdateSmartRecord, variables: { PK: this.organizationID }  }).subscribe({
        next: (eventData) => {
          let pullData = eventData.value.data.onUpdateSmartRecord;
          let subRecord =JSON.parse(pullData.data)
          //Process subRecord
          for (const key in subRecord.attributes) {
            subRecord[key] = subRecord.attributes[key].attributeValue
          }
          subRecord =  this.checkPermissons(subRecord)
          subRecord = recordToDisplay(subRecord)
          //-- Sub in table
          let exist = false
          if (subRecord.PK == this.organizationID && subRecord.shortEntity == this.pageQuery && !subRecord.SK.startsWith('#SML') ) {
            console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
            for (let index = 0; index < this.table.ram.length; index++) {
              const record = this.table.ram[index];
              if (record.SK == subRecord.SK) {
                exist = true
                this.renderComponent = false
                this.table.ram.splice(index, 1)
                this.table.body.splice(index, 1)
                if (subRecord.read == true && subRecord.filter == true) {
                  subRecord.subscribe = 'edit'
                  this.table.ram.splice(0, 0, subRecord)
                  this.table.body.splice(0, 0, subRecord)
                } 
              }
            }
            if (exist == false && subRecord.read == true && subRecord.filter == true) {
              this.renderComponent = false
              subRecord.subscribe = 'new'
              this.table.body.splice(0, 0, subRecord)
              this.table.ram.splice(0, 0, subRecord)
            }
            setTimeout(() => {
              if (subRecord.shortEntity == 'NOT') {
                this.setNotifications({action:'init'})
              }
            }, 1000);
          }else{
            if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
              //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
              
            }else{
              //console.log('no son iguales')
            }
          }
          setTimeout(() => { this.renderComponent = true }, 1);
        }
      });
      API.graphql({  query: onUpdateAttributes, variables: { PK: this.organizationID }  }).subscribe({
        next: (eventData) => {
          let pullData = eventData.value.data.onUpdateAttributes;
          let subRecord =JSON.parse(pullData.data)
          //Process subRecord
          for (const key in subRecord.attributes) {
            subRecord[key] = subRecord.attributes[key].attributeValue
          }
          subRecord =  this.checkPermissons(subRecord)
          subRecord = recordToDisplay(subRecord)
          //-- Sub in table
          let exist = false
          if (subRecord.PK == this.organizationID && subRecord.shortEntity == this.pageQuery && !subRecord.SK.startsWith('#SML') ) {
            console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
            for (let index = 0; index < this.table.ram.length; index++) {
              const record = this.table.ram[index];
              if (record.SK == subRecord.SK) {
                exist = true
                this.renderComponent = false
                this.table.ram.splice(index, 1)
                this.table.body.splice(index, 1)
                if (subRecord.read == true && subRecord.filter == true) {
                  subRecord.subscribe = 'edit'
                  this.table.ram.splice(0, 0, subRecord)
                  this.table.body.splice(0, 0, subRecord)
                } 
              }
            }
            if (exist == false && subRecord.read == true && subRecord.filter == true) {
              this.renderComponent = false
              subRecord.subscribe = 'new'
              this.table.body.splice(0, 0, subRecord)
              this.table.ram.splice(0, 0, subRecord)
            }
            setTimeout(() => {
              if (subRecord.shortEntity == 'NOT') {
                this.setNotifications({action:'init'})
              }
            }, 1000);
          }else{
            if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
              //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
              
            }else{
              //console.log('no son iguales')
            }
          }
          setTimeout(() => { this.renderComponent = true }, 1);
        }
      });
      
      API.graphql({ query: onCloneItem }).subscribe({
        next: (eventData) => {
          this.renderComponent = false
          let pullData = eventData.value.data.onCloneItem;
          let subRecord = JSON.parse(pullData.data)
          subRecord =  this.checkPermissons(subRecord)

          //Process subRecord
          for (const key in subRecord.attributes) {
            subRecord[key] = subRecord.attributes[key].attributeValue
          }
          subRecord =  this.checkPermissons(subRecord)
          subRecord = recordToDisplay(subRecord)
          //-- Sub in table
          let exist = false
          if (subRecord.PK == this.organizationID && subRecord.shortEntity == this.pageQuery && !subRecord.SK.startsWith('#SML') ) {
            if (subRecord.shortEntity == 'NOT') {
              this.setNotifications({action:'init'})
            }
            console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
            this.renderComponent = false
            subRecord.subscribe = 'new'
            this.table.body.splice(0, 0, subRecord)
            this.table.ram.splice(0, 0, subRecord)
            setTimeout(() => {
              if (subRecord.shortEntity == 'NOT') {
                this.setNotifications({action:'init'})
              }
            }, 1000);
          }else{
            if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
              //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
              
            }else{
              console.log('no son iguales')
            }
          }
          setTimeout(() => { this.renderComponent = true }, 50);
        }
      });

      API.graphql({ query: onSetItemState }).subscribe({
        next: (eventData) => {
          this.renderComponent = false
          console.log('DELETE');
          let pullData = eventData.value.data.onSetItemState;
          pullData =JSON.parse(pullData.data);
          for (let index = 0; index < this.table.ram.length; index++) {
            const element = this.table.ram[index];
            if (element.SK == pullData.SK) {
              console.log(index);
              this.table.ram.splice(index, 1)
            }
            if (element.shortEntity == 'NOT') {
              //this.refreshTable()
            }
          }
          setTimeout(() => { this.renderComponent = true }, 100);
        }
      });
      
    },
    //Table func
    async selectItemsTable(event, action, item ){
      let query = null
      let pushData = null
      let pullData = null
      let checked = false
      console.log(event, action, item);
      switch (action) {
        case 'all':
          this.table.select= []
          console.log(event.target.checked);
          if (event.target.checked == true){
            checked = true
          }
          for (let index = 0; index < this.table.ram.length; index++) {
            const element = this.table.ram[index];
            document.getElementById(index+element.SK).checked= checked
            if (checked == true) { 
              this.table.select.push({
                PK:element.PK,
                SK:element.SK,
              })
            }
          }
        break;
        case 'query':
          try {
            pushData = await API.graphql({
              query: smd_backendCustomQuery,
              variables: { 
                items: JSON.stringify(this.table.select), operation: event
              },
            }); 
            pullData = pushData.data.smd_backendCustomQuery
            pullData = JSON.parse(pullData.data)
            console.log('>> Push query Respomse', pullData);
            this.setResponseActionTable(pullData)
            if (pullData.blank == true) {
              let url = this.$router.resolve({ name:'TableResponse',paht:'/tableresponse'}); 
              console.log(url)
              window.open(url.href, '_blank')
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
        case 'mutation':
          try {
            pushData = await API.graphql({
              query: smd_backendCustomMutation,
              variables: { 
                items: JSON.stringify(this.table.select), operation: event
              },
            }); 
            pullData = pushData.data.smd_backendCustomMutation
            pullData = JSON.parse(pullData.data)
            console.log('>> Push mutation Respomse', pullData);
            this.setResponseActionTable(pullData)
            if (pullData.blank == true) {
              let url = this.$router.resolve({ name:'TableResponse',paht:'/tableresponse'}); 
              console.log(url)
              window.open(url.href, '_blank')
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
        default:
          if (event.target.checked == true) {
            this.table.select.push({
              PK:item.PK,
              SK:item.SK,
            })
          } else {
            let rIndex = this.table.select.findIndex(object => {
              return object.SK === item.SK;
            });
            this.table.select.splice(rIndex, 1); 
          }
        break;
      }
    },
    searchTable(){
      this.renderComponent = false
      let pushData = []
      loading('show', 'searchTable')
      let table = this.table.body
      //console.log(table.length);
      for (let index = 0; index < table.length; index++) {
        const element = table[index];
        let exist = false
        
        for (let zindex = 0; zindex < this.table.headers.length; zindex++) {
          const header = this.table.headers[zindex].attributeName;
          const type = this.table.headers[zindex].inputType;
          let value = ''
          try {
            value = element[header]
            if (type == 'combo') {
              value = ''
              for (let vindex = 0; vindex < this.table.headers[zindex].value.length; vindex++) {
                const sheader = this.table.headers[zindex].value[vindex].attributeName;
                for (const key in element[sheader]) {
                  if (typeof element[sheader][key] != 'string') {
                    value = value+' '+ JSON.stringify(element[sheader][key])
                  }
                  else{
                    value = value+' '+element[sheader][key]
                  }
                }
              }  
              
            }
            if (type == 'date') {
              value = formatData('date', value)[0] +' '+ formatData('date', value)[1]
            }
            if (typeof value != 'string') {
              value = JSON.stringify(element[header])
            }
            if (value != '' && value != undefined ) {
              value = value.toLowerCase()
            }else{
              continue
            }
            if (value.includes(this.search.toLowerCase())) {
              exist = true
            }
          } catch (error) {
            console.log(type+': \n ', value);
            console.log(error);
          }
        }
        if (exist == true) {
          pushData.push(table[index])
        }
      }
      this.table.ram = pushData
      this.table.pagination[1]  = 0
      this.table.pagination[2]  = 1
      setTimeout(() => { this.renderComponent = true }, 50);
      loading('hidden', 'searchTable')
    },
    sortTable(n) {  
      try {
        let action = {
          att: this.table.headers[n-1].attributeName,
          order:  this.table.headers[n-1].order,
          type: 'text',
        }
        if (this.table.headers[n-1].inputType == 'Date') {
          action.type = 'date'
        }
        orderArray(this.table.ram, action)
        
      } catch (error) {
        console.log(error);
      }
      return
      loading('show','sortTable')
      console.log(' Short Table: '+n);
      console.log( this.table.headers[n-1].inputType);
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById("myTable");
      switching = true;
      dir = "asc";
      while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 0; i < rows.length - 1; i++) {
          shouldSwitch = false;
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i+1].getElementsByTagName("TD")[n];
          console.log(x, y);
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          switchcount++;
        } else {
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
      loading('hidden', 'sortTable')
    },
    actionCalendar(action, id, data) {
      console.log('Action Calendar');
      let date = {
        day: new Date(data).getDate(),
        month:new Date(data).getMonth(),
        year:new Date(data).getFullYear(),
        availability : []
      }
      //this.table.calendar = []
      this.table.calendar = { 
        '7:00':[], '7:30':[], '8:00':[], '8:30':[], '9:00':[], '9:30':[], '10:00':[], '10:30':[], '11:00':[], '11:30':[], '12:00':[],  '12:30':[], '13:00':[], '13:30':[], '14:00':[], '14:30':[], '15:00':[], '15:30':[], '16:00':[], '16:30':[], '17:00':[], '17:30':[], '18:00':[], '18:30':[], '19:00':[]
      }
      if (this.table.calendarDay) {
        for (let index = 0; index < this.table.ram.length; index++) {
          const record = this.table.ram[index];
          if (this.pageQuery == 'CAL') {
            if (record.date == this.table.calendarDay ) {
              this.table.calendar[record.time].push(record)
            }
          }else{
            if (record.createdAt.split("-")[0] == this.table.calendarDay ) {
              this.table.calendar[record.createdAt.split("-")[0]].push(record)
            }
          }
        }
      }
      console.log(this.table.calendar);
      if (this.table.calendarDay) {
        date.select  =new Date(this.table.calendarDay)
      }
      actionCalendar(action, id, date)
    },
    displayTable(){
      console.log(this.table.display);
      if (this.table.display == 'calendar') {
        this.actionCalendar('init', 'table', new Date())
      }
    },
    async saveFilters(){
      loading('show','saveFilters')
      let pushData = ''
      let pullData = ''
      let filters = this.dataUser.preferences
      try {
        if (filters) {
          filters = filters.value
          if (!filters.some(obj => obj.module === this.pageQuery)) {
            filters.push({ 
              module: this.pageQuery,  
              defaultQuery: JSON.stringify({
                status: this.table.filterStatus ,
                index: this.table.index ,
                range: this.table.date ,
              })
            })
          }else{
            let index = filters.findIndex((obj) => obj.module === this.pageQuery)
            filters[index]= { 
              module: this.pageQuery,  
              defaultQuery: JSON.stringify({
                status: this.table.filterStatus ,
                index: this.table.index ,
                range: this.table.date ,
              })
            }

          }
        }else{
          filters = [
            { 
              module: this.pageQuery,  
              defaultQuery: JSON.stringify({
                status: this.table.filterStatus ,
                index: this.table.index ,
                range: this.table.date ,
              })
            }
          ]
        }
          pushData = [{
            attributeName: 'preferences',
            attributeValue: JSON.stringify(filters),
            attributeType: 'L',
          }]
          let replace = this.organizationID.replace('ORG#','').toLowerCase()
          console.log({ 
              PK: this.organizationID,
              SK: 'USR#'+this.user.replace(replace+'.',''),
              attributes: JSON.stringify(pushData) ,
            })
          pullData = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: this.organizationID,
              SK: 'USR#'+this.user.replace(replace+'.',''),
              attributes: JSON.stringify(pushData) ,
            },
          });
          pullData = pullData.data.smd_updateAttributes
        } catch (error) {
          console.log(error);
          loading('hidden')
          popUp(error, 'response', 'err')
        }
      loading('hidde','createLead')
    },
    //Record Actions
  },
  computed: {
    ...Vuex.mapState([
      'organizationID', //Organization Id
      'organizationData', //Organization Data
      'entities',//Entityes
      'dataModule', // Data of Focus Module
      'pageQuery', // Short Entity of Focus Module
      'recordLists', // List of preloaded records
      'userPermissions', // Permits
      'user', // Permits
      'dataUser',// Data of user 
      'preloadLists',  //List of preloaded items
      'searchContact', // Value Global input 
    ]),
  },
  watch:{
    pageQuery:function(value) {
      this.table.filterStatus=''
      this.table.index=''
      this.table.date=''
      this.renderComponent = false
      setTimeout(() => { this.renderComponent = true }, 10);
      this.initTable(value)
    },
  }
}
</script>
