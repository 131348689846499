
<template>
  <br>
  <div class="smart-wrap" style=" position: relative;">
    <div class="element-wrap col-md-6">
      <h5> {{$router.currentRoute.value.query.PK}}</h5>
      <span> {{$router.currentRoute.value.query.SK}}</span>
    </div>
    <div class="element-wrap col-md-6">
      <router-link :to="{ paht:'/', query:{name: '#SML'}}" >
      <ui-button style="margin: 0 0 0 10px;" :color="'red'" :position="'right'" :text="'Back'" />
      </router-link>
      <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Save and Back'" v-if="mode == 'edit'" v-on:click="updateMetaLayout($router.currentRoute.value.query.SK, true )" />
      <ui-button :color="'green'" :position="'right'" :text="'Save'" v-if="mode == 'edit'" v-on:click="updateMetaLayout($router.currentRoute.value.query.SK, false )" />

      <ui-button :color="'green'" :position="'right'" :text="'Save and Back'" v-if="mode == 'new'" v-on:click="updateMetaLayout(undefined, true)" />
      <ui-button :color="'green'" :position="'right'" :text="'Created'" v-if="mode == 'new'" v-on:click="updateMetaLayout(undefined, false)" />
    </div>
    <div class="input-wrap col-md-3">
      <label for="">Name Of Smart Layout</label>
      <input type="text" v-model="name" />
    </div>
    <div class="input-wrap col-md-3">
      <label for="">Attribute Name</label>
      <input type="text" v-model="attributeName" />
    </div>
    <div class="input-wrap col-md-2">
      <label for="">Layout Category</label>
      <select  v-model="category">
        <option selected disabled hidden>select an option</option>
        <option value="form">Form</option>3
        <option value="menu">Menu</option>
        <option value="post">Post</option>
        <option value="webpage">Webpage</option>
        <option value="quiz">Quiz</option>
        <option value="smartForm">Smart Form</option>
      </select>
    </div>
    <div class="input-wrap col-md-2">
      <label for="">Layout Type (remove)</label>
      <select  v-model="layoutType">
        <option selected disabled hidden>select an option</option>
        <option value="form">Form</option>
        <option value="table">Table</option>
        <option value="dataStructure">Data Sttructure</option>
        <option value="webPage">Web Page</option>
        <option value="post">Post</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="input-wrap col-md-2">
      <label for="">System Type</label>
      <select  v-if="userPermissions.includes('Superadmin')" name="" id="" v-model="systemType">
        <option selected disabled hidden>select an option</option>
        <option value="#SMARTDASH">System</option>               
        <option :value="item.value" v-for="(item, index) in preloadLists['#ORG']" :key="index">
          {{ item.value.replace("ORG#", "") }}
        </option>               
      </select>
      <select  v-else name="" id="" v-model="systemType">
        <option selected disabled hidden>select an option</option>
        <option value="#SMARTDASH">System</option>               
        <option :value="organizationID" >
          {{ organizationID.replace("ORG#", "") }}
        </option>               
      </select>
    </div>
    <div class="input-wrap col-md-12">
      <label for="">Description</label>
      <textarea  v-model="description" cols="20" rows="6" style="height: 60px;"></textarea>
    </div>
    <div v-if="category == 'webpage' || category == 'quiz'" class="input-wrap col-md-12">
      <h6 for="" >Language</h6>
      <ul class="list-group col-md-4 smart-wrap" >
        <li class="list-group-item " v-for="(item, index) in idioms" :key="index" style="padding:10px 0px 10px 10px; display:block;" > 
          <select name="" id="" v-model="idioms[index]" class="col-10" style="display:block; float:left; width:calc(100% - 50px);">
            <option v-show="!idioms.includes(vindex)" :value="vindex" v-for="(idiom, vindex) in srcIdioms" :key="vindex">{{idiom}}</option>
          </select> 
          <div class="options" style="display:block; float:left; width:40px; margin:0 0 0 10px;" >
            <ui-accion-button action='delete' v-on:click="idioms.splice(index, 1)"/>
          </div>
        </li> 
        <li class="list-group-item add-table" style="text-align:center; cursor:pointer;" v-on:click="idioms.push('')" > 
          Add New 
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="#5a5a5a" stroke-width="2"><path stroke-linecap="round" d="M12 8v4m0 0v4m0-4h4m-4 0H8"/><circle cx="12" cy="12" r="10"/></g></svg>
        </li> 
      </ul>
    </div>
  </div>
  <div class="smart-wrap" >  
    <div class="col-md-8">
      <div class="row">
        <div class="input-wrap col-md-2">
          <label for="">Language</label>
          <select name="" id=""   v-model="language">
            <option :value="item" v-for="(item, index) in idioms" :key="index" >{{item}}</option>
          </select>
        </div>
        <div class="input-wrap col-md-2">
          <label for="">Width Canvas</label>
          <input type="number" name="" id="" v-model="widthCanvas"> 
        </div>
        <div class="input-wrap col-md-8">
          <label for="">Style Canvas</label>
          <textarea name="" id="" cols="30" rows="4" v-model="styleCanvas"></textarea>
        </div>
      </div>
      <div class="list-group" style="position:relative;" v-if="layoutType == 'post'">
        <h6>Versions</h6> 
        <div class="btn btn-repeatable"  v-on:click="actionVersion('add')" >
          <i class='bx bx-plus' ></i>
        </div>
        <br>
        {{versionView}} 
        <div class="list-group-item list-group-item-action " v-for="(version, index) in layoutVersions" :key="index" :style="setStyleVersion(index)">
          <div class="row">
            <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> <input type="text" v-model="layoutVersions[index].name"> </div>
            <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> 
              <select name="" id="" v-model="layoutVersions[index].format" v-on:change="setFrame(index)">
                <option value="none">None </option>
                <option value="draft">Draft</option>
                <option value="twiterPost">Twiter Post</option>
                <option value="twiterHeader">Twiter Header</option>
                <option value="facebookPost">Facebook Post</option>
                <option value="instagramPost">Instagram Post</option>
                <option value="instagramStory">Instagram Story</option>
                <option value="dribbbleShot">Dribbble Shot</option>
                <option value="linkedinCover">Linkedin Cover</option>
              </select>
            </div>
            <div class="options">
              <div class="col edit" style="float:rigth; cursor: pointer; " v-on:click="actionVersion('view', index)" >
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.55917 15.0159H5.68547C5.27612 15.016 4.87664 14.8901 4.54129 14.6554C4.20593 14.4206 3.95094 14.0884 3.81094 13.7037L0.0618791 3.39209C-0.0707341 3.02816 0.0200011 2.62036 0.293204 2.34617L0.585352 2.05403L6.98867 8.45717C6.98767 8.4831 6.98069 8.50902 6.98069 8.53494C6.98069 8.83074 7.06841 9.1199 7.23275 9.36585C7.3971 9.6118 7.63068 9.80349 7.90398 9.91669C8.17727 10.0299 8.47799 10.0595 8.76812 10.0018C9.05824 9.94409 9.32474 9.80165 9.53391 9.59249C9.74307 9.38332 9.88552 9.11683 9.94323 8.82672C10.0009 8.5366 9.97132 8.23589 9.85812 7.9626C9.74492 7.68932 9.55322 7.45574 9.30726 7.2914C9.06131 7.12706 8.77214 7.03935 8.47633 7.03935C8.45041 7.03935 8.42448 7.04633 8.39856 7.04732L1.99524 0.644182L2.28739 0.352044C2.42145 0.217648 2.59119 0.124444 2.77654 0.0834522C2.96189 0.0424605 3.1551 0.0553948 3.33334 0.120725L13.6453 3.87068C14.0299 4.01068 14.3622 4.26566 14.5969 4.601C14.8317 4.93635 14.9575 5.33581 14.9574 5.74516V9.61875L16.9506 11.6109L11.5524 17.009L9.55917 15.0159V15.0159ZM17.8879 12.9599L12.9024 17.9452L15.0163 20.06L20.0017 15.0747L17.8879 12.9599Z" fill="white"/>
                </svg>
              </div>
              <div class="col delete" style="float:rigth; cursor: pointer;" v-on:click="actionVersion('delete', index)" >
                <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                  <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="smartlayout" :style="'border: 1px solid #a9a9a9; position: relative; width:'+widthCanvas+'%; overflow:hidden; padding:0px;' ">
        <!-- Constructor -->
        <paper-web-constructor  :listDrop="customLayout" :data="data" v-if="layoutType == 'webPage' && customLayout != null" :frame="layoutFormats[frame]" :styleDragg="styleCanvas" :idioms="idioms" :language="language"/>
        <paper-constructor  :listDrop="customLayout" :data="data" v-if="(layoutType == 'form' || layoutType == 'post' ) && customLayout != null" :frame="layoutFormats[frame]" :styleDragg="styleCanvas" :idioms="idioms" :language="language"/>
        <div class="element-wrap col-md-12" v-if="layoutType == 'table' ">
          <table class="table" id="myTable">
              <draggable
                v-bind="dragOptions"
                class="dragArea"
                :list="smartComponents"
                group="inputs"
                @change="log"
                item-key="no"
                tag="thead"
              > 
                <template #item="{ element }">
                  <th>
                    {{element.label}}
                  </th>
                </template>
              </draggable>
            <tbody>
              <tr>
                <td v-for="(item, index) in smartComponents" :key="index" >...</td> 
              </tr>
            </tbody>
          </table>
        </div>
        <div class="element-wrap col-md-12" v-if="layoutType == 'dataStructure' ">
          <draggable
            v-bind="dragOptions"
            class="dragArea list-group"
            style="padding:10px; border:1px dashed #ccc;"
            :list="smartComponents"
            group="inputs"
            @change="log"
            item-key="no"
            tag="div"
          > 
            <template #item="{ element }">
              <a  class="list-group-item list-group-item-action">{{element.attributeName}}
                <draggable
                  v-bind="dragOptions"
                  style="padding:10px; border:1px dashed #ccc;"
                  class="dragArea list-group"
                  :list="element.smartComponents"
                  group="inputs"
                  @change="log"
                  item-key="no"
                  tag="div"
                  v-if="element.smartComponents.length != 0"
                > 
                  <template #item="{ element }">
                    <a  class="list-group-item list-group-item-action">
                      {{element.attributeName}}
                    </a>
                  </template>
                </draggable>
              </a>
            </template>
          </draggable>
        </div>
      </div>
    </div>
    <div class="element-wrap col-md-4 " id="metafields" style="z-index: 0;">
      <div class="tabs" id="stickyPanel">
        <div id="tab-options" class="options" >
          <span id="tab-1" class="active" v-on:click="setTab('1')" >Components</span>
          <span id="tab-2" v-on:click="setTab('2')" >Layers</span>
        </div>
        <div id="tab-content" class="content">
          <div id="content-1" class="tab show" style="overflow: auto; height: 86vh;">
            <div class="input-wrap col-md-12">
              <label for="">Components</label>
              <select class="form-control" v-model="metaItems" style=" height: 40px;" v-on:change="GetMetaItems(metaItems)">
                <option selected>Select a Optiopn</option>
                <option value="#MIN">Meta Inputs</option>
                <option value="#MEL">Meta Elements</option>
                <option value="#SML">Layout</option>
                <option value="#PAG">Modules</option>
                <option value="#BAT">Basic Attributes</option>
              </select>
            </div>
            <div class="input-wrap col-md-12">
              <label for="">Search for names..</label>
              <input type="text" id="f_search"  v-on:keyup="f_search" title="Type in a name" v-model="w_search" style="width: 100%; margin: 0 0 0 0;  height: 40px;"  />
            </div>
            <draggable v-bind="dragOptions" id="listSmart" class="dragArea list-group fronter smart-wrap" style="width: 100%;" :list="inputsdragg" :group="{ name: 'components', pull: 'clone', put: false }" :clone="cloneInputs" @change="log"  item-key="no" >
              <template #item="{ element }">
                <div class="list-group-item" :title="'PK:'+element.PK+'\nSK:'+element.SK" style="width: 100%;">
                  {{ element.attributeName }}
                </div>
              </template>
            </draggable>
          </div>
          <div id="content-2" style="overflow: auto;height: 86vh;" class="tab">
            <h4 style="padding:10px 0;"> Layers </h4>
            <paper-layers v-if="customLayout != null" :data="customLayout" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row form-row col-md-8">
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">Groups Can Edit</label>
        <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
          <input type="checkbox" class="box" :value="check.value" v-model="groupsCanEdit"/>
          <span class="text" > {{check.value}} </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">Groups Can Read</label>
        <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
          <input type="checkbox" class="box" :value="check.value" v-model="groupsCanRead"/>
          <span class="text" > {{check.value}} </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">User Can Edit</label>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@author" v-model="usersCanEdit"/>
          <span class="text" > @Author </span>
        </div>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@client" v-model="usersCanEdit"/>
          <span class="text" > @Client </span>
        </div>
        <div class="envelop permissions" >
          <input type="checkbox" class="box" value="Responsible" v-model="usersCanEdit"/>
          <span class="text" > Responsible </span>
        </div>
      </div>
    </div>
    <div class="col col-md-3" >
      <div class="check" >
        <label for="">User Can Read</label>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@author" v-model="usersCanRead"/>
          <span class="text" > @Author </span>
        </div>
        <div class="envelop permissions">
          <input type="checkbox" class="box" value="@client" v-model="usersCanRead"/>
          <span class="text" > @Client </span>
        </div>
        <div class="envelop permissions" >
          <input type="checkbox" class="box" value="Responsible" v-model="usersCanRead"/>
          <span class="text" > Responsible </span>
        </div>
      </div>
    </div>
  </div>
  <ui-modal name="custom" type="custom" >
    <template v-if="customItem != null" #content>
        <div class="panel smart-wrap">
          <div class="element-wrap col-md-12" style="margin:0;">
            <h4>{{customItem.attributeName}}</h4>
            <p>{{customItem.id}}</p>
          </div>
          <div class="input-wrap col-md-2" style="margin:0;">
            <label for="">Version</label>
            <input type="number" v-model="customItem.version" >
          </div>
          <div class="input-wrap col-md-8" style="margin:0;">
            <label for="">Attribute Name</label>
            <input type="text" v-model="customItem.attributeName" >
          </div>
          <div class="input-wrap col-md-2 " style="margin:0;">
            <div class="check-wrap">
              <label for="">Print</label>
              <div class="envelop">
                <input type="checkbox" name="" id="" v-model="customItem.print">
              </div>
            </div>
          </div>
          <div class="input-wrap col-md-12" style="margin:0;">
            <label for="">ID</label>
            <input type="text" v-model="customItem.id" >
          </div>
          <!-- Styles -->
          <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px"> Styles </h6>
            <div class="input-wrap col-md-12"> 
              <label > Class </label>
              <input type="text" v-model="customItem.class">
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Style Wrap</label>
              <textarea rows="3" v-model="customItem.styleWrap"></textarea>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Style Component</label>
              <textarea rows="3" v-model="customItem.styleComponent"></textarea>
            </div> 
          </div>

          <!-- Element -->
          <div v-if="customItem.metaType == 'metaElement'" class="elemet-wrap row col-md-12"  style="background: #B0F5013d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px">Element </h6>
            <div class="input-wrap col-md-12" v-if="customItem.elementType == 'smart-button'">
              <label > Type Button </label>
              <select name="" id="" v-model="customItem.buttonType">
                <option value="link">Front End</option>
                <option value="queryButton ">Query Button </option>
                <option value="mutationButton">Mutation Button</option>
              </select>
            </div>
            <div class="input-wrap col-md-6" v-for="(item, index) in idioms" :key="index" >
              <label > Text {{item.toUpperCase()}}</label>
              <textarea rows="5" v-model="customItem.text[item]" ></textarea>
            </div>
            <div class="input-wrap col-md-10"> 
              <label > URL / Image</label>
              <input type="text" v-model="customItem.url"  />
            </div> 
            <div class="input-wrap col-md-2"> 
              <label > Target (Change) </label>
              <input type="text" v-model="customItem.target"  />
            </div>
            <div class="input-wrap col-md-12"> 
              <label >Query Parameters (GRAPHQL, Preload List, Button)</label>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>

          <!-- Input -->
          <div v-if="customItem.metaType == 'metaInput'" class="elemet-wrap row col-md-12"  style="background: #F5E0013d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px"> Input </h6>
            <div  class="input-wrap col-md-6"> 
              <label > Input Type </label>
              <select v-model="customItem.inputType">
                <option value="Select" >Select</option>
                <option value="Text" >Text</option>
                <option value="HTML" >HTML</option>
                <option value="preloadList" >Preloaded List</option>
                <option value="structureMaker" >Data Structure Maker</option>
                <option value="GRAPHQL" >GRAPHQL</option>
                <option value="GRAPHQLPLUS" >GRAPHQLPLUS</option>
                <option value="TextArea" >Text Area</option>
                <option value="File" >File</option>
                <option value="Date" >Date</option>
                <option value="Number" >Number</option>
                <option value="Phone" >Phone</option>
                <option value="Combo" >Combo</option>
                <option value="CheckBox" >CheckBox</option>
                <option value="RadioButton" >RadioButton</option>
                <option value="Boolean" >Boolean</option>
                <option value="Currency" >Currency</option> 
                <option value="Email" >Email</option>
                <option value="ZipCode" >ZipCode</option>
                <option value="JSON" >JSON</option>
                <option value="Range" >Range</option>
                <option value="Color" >Color</option>
                <option value="Editor" >Editor</option>
                <option value="Counter" >Counter</option>
                <option value="Computed" >Computed</option>
                <option value="quote" >Quote</option>
                <option value="cognitoGroups" >Cognito Groups</option>
              </select>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label > Format Type </label>
              <select v-model="customItem.format">
                <option value="numeric">Numeric</option>
                <option value="string">String</option>
                <option value="currency">Currency</option>
              </select>
            </div>
            <div class="input-wrap col-md-12"> 
              <label > Input Label </label>
              <input type="text" v-model="customItem.label"  />
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Repeatable</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.isRepeatable">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Read Only</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.readOnly">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Client Input</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.smartInput">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Required</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.required">
                </div>
              </div>
            </div> 
            <div  class="input-wrap col-md-12"> 
              <label > Input Additionl Description </label>
              <textarea rows="5" v-model="customItem.description"></textarea>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Input Help </label>
              <textarea name="" id="" cols="30" rows="5" v-model="customItem.help"></textarea>
            </div>
            <div class="input-wrap col-md-12"> 
              <label for="">Options List (Select, Checks, Radio, etc)</label>\
              <textarea id="custom-valueOptions" rows="5" :value="JSON.stringify(customItem.valueOptions) " v-on:keyup="validationInputJSON('custom-valueOptions', 'valueOptions')"></textarea>
            </div>
            <div class="input-wrap col-md-12" > 
              <label > File Security Level </label>
              <select v-model="customItem.securityLevel">
                <option value="public">Public</option>
                <option value="protected">Protected</option>
                <option value="private">Private</option>
                <option value="open">None</option>
              </select>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label >Query Parameters (GRAPHQL, Preload List)</label>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>
          <div v-if="customItem.metaType == 'metaLayout'" class="elemet-wrap row col-md-12"  style="background: #00F5C93d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px">Layout</h6>
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Binded-layout</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.web">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Repeatable</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.isRepeatable">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label >Query Parameters (GRAPHQL, Preload List)</label>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>
          
          <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d;">
            <h6 class="col-md-12" style=" padding: 10px"> Permissions </h6>
            <div class="input-wrap col-md-6"> 
              <label for=""> Groups Can Edit</label> <br>
              <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                <input type="checkbox" class="box" :value="check.value" v-model="customItem.groupsCanEdit"/>
                <span class="text" >&nbsp; {{check.value}} </span>
              </div>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">Groups Can Read</label><br>
              <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                <input type="checkbox" class="box" :value="check.value" v-model="customItem.groupsCanRead"/>
                <span class="text" >&nbsp; {{check.value}} </span>
              </div>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">User Can Edit</label>
              <br>
              <input type="checkbox" class="box" value="@author" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Author </span>
              <br>
              <input type="checkbox" class="box" value="@client" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Client </span>
              <br>
              <input type="checkbox" class="box" value="@responsible" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Responsible </span>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">User Can Read</label>
              <br>
              <input type="checkbox" class="box" value="@author" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Author </span>
              <br>
              <input type="checkbox" class="box" value="@client" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Client </span>
              <br>
              <input type="checkbox" class="box" value="@responsible" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Responsible </span>
            </div> 
          </div>
          <paper-displayer :value="customItem"/>
        </div>
        <div class="action">
          <ui-button text="Apply"  style="width: calc(40% - 10px); float:left; margin:0 5px;" color="green" v-on:click="applyCustomItem([customItem,'edit'])" />
          <ui-button text="Clone"  style="width: calc(20% - 10px); float:left; margin:0 5px;" color="blue" v-on:click="applyCustomItem([customItem,'clone'])" >
          </ui-button>
          <ui-button text="Remove"  style="width: calc(20% - 10px); float:left; margin:0 5px;" color="red" v-on:click="applyCustomItem([customItem, 'delete'])" />
          <ui-button text="X" style="width: calc(20% - 10px); float:left; margin:0 5px;" data-dismiss="modal" />
        </div>
    </template>
  </ui-modal>
</template>

<script>
//Tools
import Vuex from "vuex";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';  
import Swal from 'sweetalert2'
import { validationInputJSON } from "../../store/tools";
//AWS - GRAPHQL
import { smd_listMetaEntity, smd_getRecord2, smd_listEntityObj } from '../../graphql/queries'
import { smd_updateSmartLayout } from '../../graphql/mutations'
//Components
import draggable from "vuedraggable";
import PaperDisplayer from "./PaperDisplayer.vue";
import PaperLayers from "./PaperLayers.vue";
import PaperConstructor from "./PaperConstructor.vue";
import UiButton from '../ui/UiButton.vue'
import UiAccionButton from '../ui/UiAccionButton.vue'
import UiModal from '../ui/UiModal.vue'
import PaperWebConstructor from './PaperWebConstructor.vue';

export default {
  name: 'paper-smart',
  components:{
    draggable,
    PaperDisplayer,
    PaperLayers,
    PaperConstructor,
    UiButton,
    UiAccionButton,
    UiModal,
    PaperWebConstructor,
  },
  data() {
    return {
      w_metaItems:"",
      backgroundLayout:'#f0f0f0',
      metaItems:"#MEL",
      category:"",
      //----
      component: "",
      w_search: "",
      trash: [{text:'test'}],
      usersCanEdit:[ "Admin", "Superadmin", "Editor" ],
      usersCanRead:["Admin","Superadmin","Editor"],
      groupsCanEdit:[""],
      groupsCanRead:[""],  
      //Data Product Default
      inputsdragg: [],
      item: "",
      mode: "",
      //Table
      title: "System",
      subTitle: "System",
      //Data Meta Form
      name: "",
      notes: "",
      description: "",
      attributeName: "",
      editPK: "",
      editSK: "",
      metaEntityType: "",
      systemType: "",
      layoutType:"form",
      data:[],
      smartComponents:[],
      //Canvas
      widthCanvas:100,
      styleCanvas: 'padding:2 0px;',
      frame:100,
      layoutVersions: [],
      versionView:0,
      layoutFormats: {
        none: ' ',
        draft:'width: 1000px; height:1000px;', 
        twiterPost:'width: 1012px; height:506px;', 
        twiterHeader: 'width: 1500px; height:500px;', 
        facebookPost:'width: 1200px; height:630px;', 
        instagramPost :'width: 1080px; height:1080px;', 
        instagramStory:'width: 1000px; height:1920px;', 
        dribbbleShot:'width: 400px; height:300px;',
        linkedinCover:'width: 1584px; height:396px;',
      },
      //idioms
      idioms:['en','es',],
      language:'en',
      srcIdioms:{
        en:'English',
        es:'Spanish',
        it:'Italian',
        fr:'French',
        de:'German',
      },
    };
  },
  created() {
    let stickyPanel = false
      $(window).scroll(function(event) {
        let scrollTop = $(window).scrollTop();
        if (scrollTop > 350 && stickyPanel == false) {
          document.getElementById('stickyPanel').classList.add('sticky')
          stickyPanel = true
        }
        if (scrollTop < 350 && stickyPanel == true) {
          document.getElementById('stickyPanel').classList.remove('sticky')
          stickyPanel = false
        }
      });
    if (this.$router.currentRoute.value.query.SK) {
      console.log(
        "%cEdit Mode\nPK:" +
          this.organizationID +
          ",  SK:" +
          this.$router.currentRoute.value.query.SK,
        "color:#6BF01A"
      );
      this.getMetaLayout();
      this.mode = "edit";
    } else {
      console.group("%cNew Mode\nPK:" + this.organizationID, "color:#6BF01A");
      this.mode = "new";
    }
    this.GetMetaItems("#MEL");
  },
  methods: {
    ...Vuex.mapMutations(['setCustomLayout','applyCustomItem']),
    //Tools
    validationInputJSON(id, att) {
      console.clear();
      let input = document.getElementById(id)
      console.log(input);
      console.log(input.value);
      try {
        this.customItem[att] = JSON.parse(input.value)
        input.style = ''
      } catch (error) {
        input.style = 'background: #ff3030; color: #fff;'
      }
    },
    loading(action){
      if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
      }else{
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
      }
    },
    async GetMetaItems(metaItems) {
      console.log(metaItems); 
      const wo_recordPullTable = await API.graphql({
        query: smd_listEntityObj,
        variables: {
          active: '1' ,
          PK: '#SMARTDASH',
          shortEntity: metaItems,
          pageSize: 0 ,
        },
      });
      console.log(JSON.parse(wo_recordPullTable.data.smd_listEntityObj.data).length);
      this.w_metaItems = ''
      this.w_metaItems = JSON.parse(wo_recordPullTable.data.smd_listEntityObj.data);
      let inputsdragg = []
      this.inputsdragg = []
      if (this.w_metaItems.length <= 0) {
        this.w_metaItems = [{}]
      }
      function f_compoentConstructor( item , type) {
        console.log('inputsdragg');
        for (let index = 0; index < item.length; index++) {
          console.log(type);
          console.log(item[index]);
          try {
            let att = ''
            if (item == [{}]) {
            }else{
              //console.log(item[index]);
              att = item[index].attributes
            }
            switch (type) {
              case '#SML':
                inputsdragg.push({
                  no: "collapse-"+index,
                  PK: item[index].PK,
                  SK: item[index].SK,
                  metaType: 'metaLayout',
                  layoutItemType:'backend',
                  style: 'width: 50%;',
                  layoutType:'layout',
                  attributeName: att['smartlayout.name'].attributeValue,
                  label: att['smartlayout.name'].attributeValue,
                  smartComponents: JSON.parse(att['smartlayout.Layout'].attributeValue),
                })
              break;
              case '#MIN':
                inputsdragg.push({
                  no: "collapse-"+index,
                  PK: item[index].PK,
                  SK: item[index].SK,
                  metaType: 'metaInput',
                  layoutItemType:'backEnd',
                  attributeName: att['metaInput.attributeName'],
                  inputType: att['metaInput.inputType'],
                  label: att['metaInput.metafieldLabel'], 
                  isRepeatable: false, 
                  style: 'width: 50%;',
                  metaJSON: att['metaInput.metaJSON'],
                  smartComponents: []
                });
              break;
              case '#MEL':
                inputsdragg =[
                  {
                    elementType:'Input',
                    attributeName: 'Input',
                    name: 'Input',
                    class: '',
                    styleWrap: 'width: 50%;',
                    label: 'Input'
                  },
                  {
                    elementType:'quote',
                    attributeName: 'Quote',
                    name: 'Quote',
                    class: '',
                    styleWrap: 'width: 50%;',
                    label: 'Input'
                  },
                  {
                    elementType:'smart-button',
                    attributeName: 'Button',
                    name: 'Button',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  
                  {
                    elementType:'Title',
                    attributeName: 'Title',
                    name: 'Title',
                    text: 'Main Title',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  {
                    elementType:'Div',
                    attributeName: 'Div',
                    name: 'Div',
                    smartComponents:[],
                    class: '',
                    styleWrap: 'width: 50%;',
                    styleComponent: 'padding:20px;',
                  },
                  {
                    elementType:'language',
                    attributeName: 'language',
                    name: 'language',
                    smartComponents:[],
                    class: '',
                    styleWrap: 'width: 50%;',
                    styleComponent: 'padding:20px;',
                  },
                  {
                    elementType:'Div',
                    attributeName: 'Container-in',
                    name: 'Container-in',
                    smartComponents:[],
                    class: '',
                    styleWrap: 'width: 100%; max-width: 1100px; margin:0 auto; display:block;',
                    styleComponent: 'padding:20px;',
                  },
                  {
                    elementType:'Div',
                    attributeName: 'Section',
                    name: 'Section',
                    smartComponents:[
                      {
                        attributeName: "Container-in",
                        class: "",
                        elementType: "Div",
                        id: "fc809df5-19eb-474a-9f6b-72a9ebeb3877",
                        layoutItemType: "frontend",
                        metaType: "metaElement",
                        print: true,
                        smartComponents: [],
                        styleComponent: "padding:20px;width: 100%;",
                        styleWrap: "width: 100%; max-width: 1100px; margin:0 auto; display:block;",
                        text: "Main Title",
                        url: "",
                      },
                    ],
                    class: '',
                    styleWrap: 'width: 100%; ',
                    styleComponent: 'padding:10px;',
                  },
                  {
                    elementType:'standar',
                    attributeName: 'Smart Layout',
                    name: 'Smart Layout',
                    smartComponents:[],
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  {
                    elementType:'Img',
                    attributeName: 'Img',
                    name: 'Img',
                    url:[],
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  {
                    elementType:'iframe',
                    attributeName: 'iframe',
                    name: 'iframe',
                    url:[],
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  {
                    elementType:'link',
                    attributeName: 'Link',
                    name: 'link',
                    url:window.location.origin,
                    class: '',
                    styleWrap: 'width: 50%;',
                    styleComponent: 'padding:20px;',
                  },
                  {
                    elementType:'Text',
                    attributeName: 'Text',
                    name: 'Text',
                    text: 'Text',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  
                  {
                    elementType:'Collapse',
                    attributeName: 'Collapse',
                    name: 'Collapse',
                    text: 'Collapse',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  
                  {
                    elementType:'Charts',
                    attributeName: 'Charts',
                    name: 'Charts',
                    text: 'Charts',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                  {
                    elementType:'shape',
                    attributeName: 'Shape',
                    name: 'Shape',
                    text: 'Charts',
                    class: '',
                    styleWrap: 'width: 50%;',
                  },
                ]
                for (let zindex = 0; zindex < inputsdragg.length; zindex++) {
                  inputsdragg[zindex].no = 'collapse-'+zindex
                  inputsdragg[zindex].metaType = 'metaElement'
                  inputsdragg[zindex].layoutItemType = 'frontEnd'
                  if (inputsdragg[zindex].smartComponents == undefined) {
                    inputsdragg[zindex].smartComponents = []
                  }
                }
              break;
              case '#PAG':
                inputsdragg.push({
                  no: "collapse-"+index,
                  PK: item[index].PK,
                  SK: item[index].SK,
                  metaType: 'metaModule',
                  layoutItemType:'backEnd',
                  attributeName: att['modules.pageName'].attributeValue,
                  dataQuery: att['modules.dataQuery'].attributeValue,
                  dataQuery: att['modules.dataQuery'].attributeValue,
                  text: att['modules.pageName'].attributeValue,
                  style: 'width: 50%; margin:0;',
                  queryType: att['modules.queryType'].attributeValue,
                  class: '',
                  smartComponents:[]
                });
              break;
              case '#BAT':
                inputsdragg.push(
                  {
                    metaType: 'metaElement',
                    layoutItemType:'frontEnd',
                    attributeName: 'createdAt',
                    inputType: 'Text',
                    label: 'Created At', 
                  },
                  {
                    metaType: 'metaElement',
                    layoutItemType:'frontEnd',
                    attributeName: 'createdAt',
                    inputType: 'Text',
                    label: 'Created At', 
                  },
                  {
                    metaType: 'metaElement',
                    layoutItemType:'frontEnd',
                    attributeName: 'createdAt',
                    inputType: 'Text',
                    label: 'Created At', 
                  },
                );
              break;
            }
          } catch (error) {
            console.log("Fallo: \n", item[index].attributes);
            console.log(error);
          }
        }
      }
      f_compoentConstructor( this.w_metaItems, metaItems )
      this.inputsdragg = inputsdragg
      console.log(this.inputsdragg);
    },
    async getMetaLayout(){
      console.log(this.$router.currentRoute.value.query.PK)
      const wo_PullItem = await API.graphql({
        query: smd_getRecord2,
        variables: {
          PK: this.$router.currentRoute.value.query.PK,
          SK: this.$router.currentRoute.value.query.SK,
        },
      });
      let layout = wo_PullItem.data.smd_getRecord2
      layout = JSON.parse(layout.data)

      console.log("Item:", layout); // View Item
      layout.attributes.forEach(att => {
        try {          
          switch (att.attributeName) {
            case 'smartlayout.name':  
              this.name = att.attributeValue
            break;
            case 'smartlayout.layouttype':  
              this.layoutType = att.attributeValue
            break;
            case 'smartlayout.Layout':
              this.setCustomLayout([])  
              function f_filterLayout( layout ) {
                for (let vindex = 0; vindex < layout.length; vindex++) {
                  switch (layout[vindex].metaType) {
                    case 'Layout':
                      layout[vindex].metaType = 'metaLayout'
                      layout[vindex].layoutItemType = 'backEnd'
                    break;
                    case 'metaLayout':
                      layout[vindex].layoutItemType = 'backEnd'
                    break;
                    case 'metaInput':
                      layout[vindex].layoutItemType = 'backEnd'
                    break;
                  }
                  
                  if (layout[vindex].att) {
                    for (const key in layout[vindex].att) {
                      if (key != 'smartComponents') {
                        layout[vindex][key] = layout[vindex].att[key] 
                      }
                    }
                    delete layout[vindex].att
                  }
                  switch (layout[vindex].isRepeatable) {
                    case "No":
                      layout[vindex].isRepeatable = false
                    break;
                    case "Yes":
                      layout[vindex].isRepeatable = true
                    break;
                  }
                  if (layout[vindex].groupsCanEdit == undefined) {
                    layout[vindex].groupsCanEdit = ["Admin","Editor","Superadmin"]
                  }
                  if (layout[vindex].groupsCanRead == undefined) {
                    layout[vindex].groupsCanRead = ["Admin","Editor","Superadmin"]
                  }
                  if (layout[vindex].usersCanEdit == undefined) {
                    layout[vindex].usersCanEdit = ["@author"]
                  }
                  if (layout[vindex].usersCanRead == undefined) {
                    layout[vindex].usersCanRead = ["@author"]
                  }
                  if (layout[vindex].id == undefined) {
                    layout[vindex].id = uuidv4()
                  }
                  if (layout[vindex].version == undefined) {
                    layout[vindex].version = '1'
                  }
                  if (layout[vindex].print == undefined) {
                    layout[vindex].print = true
                  }
                  if (layout[vindex].styleWrap == undefined) {
                    layout[vindex].styleWrap = layout[vindex].style
                    //delete layout[vindex].style
                  }
                  if (typeof layout[vindex].text == 'string') {
                    layout[vindex].text = {en:layout[vindex].text}
                  } 
                  if (layout[vindex].smartComponents.length > 0 ) {
                    layout[vindex].smartComponents = f_filterLayout(layout[vindex].smartComponents)    
                  }
                }
                return layout
              }
              console.clear();
              this.smartComponents = f_filterLayout(JSON.parse(att.attributeValue))   
              this.setCustomLayout(this.smartComponents)  
            break;
            case 'category':
              this.category = att.attributeValue
            break;
            case 'smartlayout.description':
              this.description = att.attributeValue
            break;
            case 'smartlayout.attributeName':
              this.attributeName = att.attributeValue
            break;
            case 'smartlayout.versions':
              this.layoutVersions = JSON.parse(att.attributeValue)
            break;
            case 'idioms':
              this.idioms = JSON.parse(att.attributeValue)
            break;

          }
        } catch (error) {
          console.log('Fail', error);
        }
      });
      if (this.layoutType == 'post') {
        if (this.layoutVersions.length == 0) {
          this.layoutVersions.push({
            name:'default',
            format:'draft',
            layout: this.smartComponents
          })
          console.log(this.layoutVersions);
        }
      }
      //this.description = wo_PullItem.data.smd_getRecord.attributes[2].attributeValue
      this.systemType = layout.PK
      //Permissons
      this.groupsCanRead= layout.groupsCanRead
      this.groupsCanEdit= layout.groupsCanEdit
      this.usersCanEdit= layout.usersCanEdit
      this.usersCanRead= layout.usersCanRead
    },
    async updateMetaLayout(layoutSK, back) {
      this.loading('show');
      for (let index = 0; index < this.smartComponents.length; index++) {
        try {          
          if (this.smartComponents[index].graphQl) {
            if (typeof this.smartComponents[index].graphQl != 'object' ) {
              this.smartComponents[index].graphQl = JSON.parse(this.smartComponents[index].graphQl)
            }
          }
          if (this.smartComponents[index].smartComponents.length <= 0) {
            for (let zindex = 0; zindex < this.smartComponents[index].smartComponents.length; zindex++) {
              if (this.smartComponents[index].smartComponents[zindex].graphQl) {
                this.smartComponents[index].smartComponents[zindex].graphQl = JSON.parse(this.smartComponents[index].smartComponents[zindex].graphQl)
              }
            }
          }
        } catch (error) {    
          console.log(error);      
        }
        
      }
      const wo_pushItem = {
        shortEntity: "#SML",
        SK: layoutSK,
        PK: this.systemType,
        entity: {
          PK :'#SMARTDASH',
          SK:'#ENT#7f7099f5-8e1c-4a0b-b5da-e8f7c1dc3c1f',
        },
        attributes: [
          { 
            attributeName: 'smartlayout.name',
            attributeType: "S",
            attributeValue: this.name
          },
          { 
            attributeName: 'smartlayout.Layout',
            attributeType: "L",
            attributeValue: JSON.stringify(this.customLayout).replace(/\\/gi, "")
          },
          { 
            attributeName: 'smartlayout.versions',
            attributeType: "L",
            attributeValue: JSON.stringify(this.layoutVersions).replace(/\\/gi, "")
          },
          { 
            attributeName: 'smartlayout.description',
            attributeType: "S",
            attributeValue: this.description
          },
          { 
            attributeName: 'smartlayout.layouttype',
            attributeType: "S",
            attributeValue: this.layoutType
          },
          { 
            attributeName: 'smartlayout.attributeName',
            attributeType: "S",
            attributeValue: this.attributeName
          },
          { 
            attributeName: 'smartlayout.itemModel',
            attributeType: "S",
            attributeValue: ''
          },
          { 
            attributeName: 'category',
            attributeType: "S",
            attributeValue: this.category
          },
          { 
            attributeName: 'idioms',
            attributeType: "L",
            attributeValue: JSON.stringify(this.idioms)
          }
        ]
        ,
        //strAtt: JSON.stringify(this.smartComponents).replace(/\\/gi, ""),
        groupsCanRead: this.groupsCanRead,
        groupsCanEdit: this.groupsCanEdit,
        usersCanEdit:  this.usersCanEdit,
        usersCanRead:  this.usersCanRead,
      };
      wo_pushItem.attributes.push()
      if ( !wo_pushItem.PK || !wo_pushItem.shortEntity ) {
        console.log("Error en datos");
        console.log(wo_pushItem);
      } else {
        try {
          const pushv2 = await API.graphql({
            query: smd_updateSmartLayout,
            variables: { input: wo_pushItem },
          });
          console.log("Creado exitosamente");
          console.log(wo_pushItem);
          console.log(this.smartComponents);
          if (back == true) {
            this.$router.push({ query: { name: '#SML' } })
          }else{
            this.getMetaLayout();
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!',
            text: JSON.stringify(error),
          })
        }
      }
      this.loading('hidde');
      //this.GetMetaFields();
    },
    log: function (evt) {
      console.log(evt);
    },
    cloneInputs({ PK, SK, metaType, smartComponents , layoutType, inputType, elementType, attributeName, label, metaJSON, dataQuery, formSK, viewSK, entity, text, queryType,styleWrap, styleComponent, url} ) {
      switch (metaType) {
        case 'metaLayout':
          return {
            id:  uuidv4(),
            PK: PK,
            SK: SK,
            metaType: 'metaLayout',
            layoutItemType:'backend',
            styleWrap: 'width: 50%;',
            styleComponent: 'width: 100%;',
            layoutType: layoutType,
            attributeName: attributeName,
            label: label,
            smartComponents: smartComponents,
            isRepeatable: false,
            print: true,
          }
        break;
        case 'metaInput':
          return{
            id:  uuidv4(),
            PK: PK,
            SK: SK,
            metaType: 'metaInput',
            layoutItemType:'backEnd',
            attributeName: attributeName,
            inputType: inputType,
            label: label, 
            isRepeatable: false, 
            styleWrap: 'width: 50%;',
            styleComponent: 'width: 100%;',
            metaJSON: metaJSON,
            print: true,
            smartComponents: [],
            groupsCanEdit: [
              "Admin",
              "Editor",
              "Superadmin"
            ],
            groupsCanRead: [
              "Admin",
              "Editor",
              "Superadmin"
            ],
            usersCanEdit: [
              "@author"
            ],
            usersCanRead: [
              "@author"
            ],
          }
        break;
        case 'metaElement':
          switch (attributeName) {
            case 'Charts':
              return {
                id:  uuidv4(),
                metaType:metaType,
                layoutItemType:'backEnd',
                elementType:'Charts',
                attributeName: 'Charts',
                text: 'Charts',
                class: '',
                styleWrap: 'width: 50%;',
                styleComponent: 'width: 100%;',
                smartComponents: [],
                groupsCanEdit: [
                "Admin",
                "Editor",
                "Superadmin"
                ],
                groupsCanRead: [
                  "Admin",
                  "Editor",
                  "Superadmin"
                ],
                usersCanEdit: [
                  "@author"
                ],
                usersCanRead: [
                  "@author"
                ],
              }
            break;
            case 'Input':
              return {
                id:  uuidv4(),
                label: label,
                isRepeatable: false,
                metaType: 'metaInput',
                layoutItemType:'backEnd',
                inputType:'Text',
                attributeName: attributeName,
                class: '',
                styleWrap: 'width: 50%;',
                styleComponent: 'width: 100%;',
                print: true,
                smartComponents: [],
                groupsCanEdit: [
                "Admin",
                "Editor",
                "Superadmin"
                ],
                groupsCanRead: [
                  "Admin",
                  "Editor",
                  "Superadmin"
                ],
                usersCanEdit: [
                  "@author"
                ],
                usersCanRead: [
                  "@author"
                ],
              }
            break;
            case 'Quote':
              return {
                id:  uuidv4(),
                label: label,
                isRepeatable: false,
                metaType: 'metaInput',
                layoutItemType:'backEnd',
                inputType:'quote',
                attributeName: attributeName,
                class: '',
                styleWrap: 'width: 50%;',
                styleComponent: 'width: 100%;',
                print: true,
                smartComponents: [],
                groupsCanEdit: [
                "Admin",
                "Editor",
                "Superadmin"
                ],
                groupsCanRead: [
                  "Admin",
                  "Editor",
                  "Superadmin"
                ],
                usersCanEdit: [
                  "@author"
                ],
                usersCanRead: [
                  "@author"
                ],
              }
            break;
            case 'Smart Layout':
              return {
                id:  uuidv4(),
                label: label,
                isRepeatable: false,
                metaType: 'metaLayout',
                layoutItemType:'backEnd',
                layoutType:'layout',
                attributeName: attributeName,
                class: '',
                styleWrap: 'width: 50%;',
                styleComponent: 'width: 100%;',
                print: true,
                smartComponents: [],
                elementType:'normal',
                groupsCanEdit: [
                "Admin",
                "Editor",
                "Superadmin"
                ],
                groupsCanRead: [
                  "Admin",
                  "Editor",
                  "Superadmin"
                ],
                usersCanEdit: [
                  "@author"
                ],
                usersCanRead: [
                  "@author"
                ],
              }
            break;
            default:
              return {
                id:  uuidv4(),
                metaType: 'metaElement',
                layoutItemType:'frontend',
                elementType:elementType,
                attributeName: attributeName,
                text: 'Main Title',
                class: '',
                url: window.location.origin,
                styleWrap: styleWrap,
                styleComponent: styleComponent+ 'width: 100%;',
                print: true,
                smartComponents: smartComponents,
              }
            break;
          }
        break;
        case 'metaModule':
          return {
            id: uuidv4(),
            PK: PK,
            SK: SK,
            metaType: 'metaModule',
            layoutItemType:'backEnd',
            dataQuery: dataQuery,
            formSK: formSK,
            viewSK: viewSK,
            entity: entity,
            attributeName: attributeName,
            text: text,
            class: '',
            style: 'width: 50%;',
            smartComponents: [],
            print: true,
            queryType:queryType,
          }
        break;
      }
    },
    f_search() {
      let value =  document.getElementById('f_search').value
      let listItems = document.getElementById("listSmart");
      for (let vindex = 0; vindex < listItems.children.length; vindex++) {
        const element = listItems.children[vindex];
        if ((element.innerText.toLowerCase()).includes(value.toLowerCase()) ) {
          document.getElementById("listSmart").children[vindex].classList.remove('displayNone')
        }else{
          document.getElementById("listSmart").children[vindex].classList.add('displayNone')
        }
      }
    },
    //Component Functionality
    setTab(id){
      console.clear()
      let options = document.getElementById('tab-options')
      let content = document.getElementById('tab-content')
      content.children.forEach(element => {
        if (element.id == 'content-'+id) {
          if (element.classList[1]) {
            console.log('No esta Activo');
          }else{
            document.getElementById(element.id).classList.add('show')
            document.getElementById(element.id.replace('content-', 'tab-')).classList.add('active')
          }
        } else {
          if (element.classList[1]) {
            document.getElementById(element.id).classList.remove('show')
            document.getElementById(element.id.replace('content-', 'tab-')).classList.remove('active')
            console.log('No esta Activo');
          }else{
            //document.getElementById('content-'+id).classList.add('show')
          }
        }
        console.log(element);
      });
      //document.getElementById('tab-'+id).classList.remove('active')
      //document.getElementById('content-'+id).classList.remove('show')

      //document.getElementById('tab-'+id).classList.add('active')
      //document.getElementById('content-'+id).classList.add('show')
    },
    setFrame(index){
      if (index == this.versionView) {
        this.frame = this.layoutVersions[index].format
      }
    },
    actionVersion(action, index){
      switch (action) {
        case 'add':
          this.layoutVersions.push({
            name:'',
            format:'draft',
            layout: this.layoutVersions[0].layout
          })
          this.actionVersion('view', this.layoutVersions.length-1)
        break;
        case 'delete':
          this.layoutVersions.splice(index, 1)
        break;
        case 'view':
          this.versionView =index
          this.frame = this.layoutVersions[index].format
          this.smartComponents = this.layoutVersions[index].layout
        break;
      }
    },
    setStyleVersion(index){
      if (this.versionView == index) {
        return 'background: #3191d2;'
      }
    }
  },
  computed:{
    ...Vuex.mapState(["organizationID","permissions", 'userPermissions','preloadLists','customItem','customLayout']),
    dragOptions() {
      return {
        animation: 200,
        group: "components",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  }
}
</script>
